import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import { Paper } from "@mui/material";

const dateTimeOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "long",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  timeZone: "Europe/Berlin",
};

export default function Cards() {
  const { t } = useTranslation();
  const jobs = useSelector((state) => state.job.jobs);
  const [total, setTotal] = React.useState(0);
  const [pending, setPending] = React.useState(0);
  const [completed, setCompleted] = React.useState(0);
  const [value, setValue] = React.useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    const thisMonth = new Date().getMonth();
    const totalJobsThisMonth = jobs.filter(
      (j) => new Date(j.timestamp.seconds * 1000).getMonth() === thisMonth
    );
    const pendingJobsThisMonth = totalJobsThisMonth.filter((j) => j.status === "pending");
    const completedJobsThisMonth = totalJobsThisMonth.filter((j) => j.status === "completed");
    setTotal(totalJobsThisMonth.length);
    setPending(pendingJobsThisMonth.length);
    setCompleted(completedJobsThisMonth.length);
    return () => {};
  }, [jobs]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-4 gap-7 w-full ">
      <motion.div initial={{ y: -70 }} animate={{ y: 0 }} transition={{ duration: 0.2 }}>
        {/* <Card sx={{ minWidth: 350, padding: "16px", mr: "30px" }}> */}
        <Card
          sx={{
            // minWidth: 350,
            width: "100%",
            padding: "16px",
            mr: "30px",
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t("This Month")}
            </Typography>
            <Typography
              variant="h1"
              component="div"
              sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}
            >
              {total}
            </Typography>
            <Typography  sx={{ mb: 1.5 }} color="text.secondary">
              {t("Total Reapair Jobs")}
            </Typography>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div initial={{ y: -60 }} animate={{ y: 0 }} transition={{ duration: 0.3 }}>
        <Card sx={{  width: "100%",  padding: "16px", mr: "30px" }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t("This Month")}
            </Typography>
            <Typography
              variant="h1"
              component="div"
              sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}
            >
              {completed}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {t("Total Completed Jobs")}
            </Typography>
          </CardContent>
        </Card>
      </motion.div>
          {/* minWidth: 350, */}
      <motion.div initial={{ y: -50 }} animate={{ y: 0 }} transition={{ duration: 0.4 }}>
        <Card sx={{    width: "100%",padding: "16px", mr: "30px" }}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              {t("This Month")}
            </Typography>
            <Typography
              variant="h1"
              component="div"
              sx={{ color: process.env.REACT_APP_PRIMARY_CLR_MAIN }}
            >
              {pending}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {t("Total Pending Jobs")}
            </Typography>
          </CardContent>
        </Card>
      </motion.div>

      <motion.div initial={{ scale: 0.5 }} animate={{ scale: 1 }} transition={{ duration: 0.6 }}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: 246,
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              height: "inherit",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Clock value={value} size={170} locale={"de-DE"} renderSecondHand={false} />
            </div>
            <div style={{ fontSize: "1rem" }}>
              <Typography color="text.secondary">
                {value.toLocaleDateString("de-DE", dateTimeOptions)}
              </Typography>
            </div>
          </div>
        </Paper>
      </motion.div>
    </div>
  );
}
