import React, { useRef } from "react";

import { useDispatch } from "react-redux";

import { Edit, Print } from "@mui/icons-material";
import { Button, useMediaQuery } from "@mui/material";
import { useReactToPrint } from "react-to-print";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import DeviceProperties from "./DeviceProperties";
import { Invoice } from "../../Printings/Invoice";
import JobProperties from "./JobProperties";
import { setEditMode } from "../../../Redux/Actions";

import { useTranslation } from "react-i18next";
import { EstimationInvoice } from "../../Printings/EstimationInvoice";
import { Label } from "../../Printings/Label";
import clsx from "clsx";

const invoiceStyle = `
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 5mm;
}

@media screen {
  div.divFooter {
    /* display: none; */
  }
}
`;

const labelStyle = `
@media print {
  @page { size: 375px 185px; margin: 0;}
}
`;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(3),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export default function JobDetails({ currentJob }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const invoiceRef = useRef();
  const labelRef = useRef();
  const estimationInvoiceRef = useRef();
  const max1375 = useMediaQuery("(max-width:1375px)");
  console.log("max1375", max1375);

  const handlePrintInvoice = useReactToPrint({
    content: () => invoiceRef.current,
    pageStyle: invoiceStyle,
  });

  const handlePrintLabel = useReactToPrint({
    content: () => labelRef.current,
    pageStyle: labelStyle,
  });

  const handlePrintEstimation = useReactToPrint({
    content: () => estimationInvoiceRef.current,
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <div
        className={clsx("grid grid-cols-[20%_59%_20%] gap-3", {
          "grid-cols-2": max1375 === true,
        })}
      >
        {/* column  1 */}
        <div>
          <Item className={clsx("", { "h-full": max1375 === true })}>
            <DeviceProperties device={currentJob.device} />
          </Item>
        </div>
        {/* column 2 */}
        <div
          className={clsx("", {
            "col-span-2": max1375 === true,
          })}
        >
          <Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: max1375 === false ? "53%" : "100%",
                margin: "auto",
              }}
              className=""
            >
              <Button
                variant="outlined"
                onClick={
                  currentJob.status === "estimation"
                    ? () => handlePrintEstimation()
                    : () => handlePrintInvoice()
                }
                startIcon={<Print />}
              >
                {t("Invoice")}
              </Button>
              <Button
                variant="outlined"
                onClick={
                  currentJob.status === "estimation"
                    ? () => handlePrintEstimation()
                    : () => handlePrintLabel()
                }
                startIcon={<Print />}
              >
                {t("Label")}
              </Button>
              <Button
                variant="contained"
                startIcon={<Edit />}
                onClick={() => dispatch(setEditMode())}
              >
                {t("Edit")}
              </Button>
            </div>
            <div
              className="m-auto mt-4 max-w-[800px]"
              style={{ boxShadow: "0px 0px 11px #0000002b" }}
            >
              {currentJob.status === "estimation" ? (
                <EstimationInvoice ref={estimationInvoiceRef} />
              ) : (
                <Invoice minWidth="550px" maxWidth="" ref={invoiceRef} />
              )}
            </div>
            <div
              style={{
                boxShadow: "0px 0px 11px #0000002b",
                margin: "20px auto",
                width: "fit-content",
              }}
            >
              <Label ref={labelRef} />
            </div>
          </Item>
        </div>
        {/* column 3 */}
        <div
          className={clsx("", {
            "row-start-1 col-start-2": max1375 === true,
          })}
        >
          <Item>
            <JobProperties currentJob={currentJob} />
          </Item>
        </div>
      </div>
    </Box>
  );
}
