import * as React from "react";

import { useDispatch, useSelector } from "react-redux";
import { deleteJob, setBackdropVisible, setCurrentJob, setJobs } from "../../Redux/Actions";

import { DataGrid, deDE, GridToolbarQuickFilter } from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import "../../App.css";
import ConstructionIcon from "@mui/icons-material/Construction";
import {
  Button,
  Chip,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Add, ChangeCircleSharp, DateRange, Delete } from "@mui/icons-material";
import { collection, query, onSnapshot, orderBy, where } from "firebase/firestore";
import { fireStore } from "../../firebase/config";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import JobsRange from "../../components/Job/JobsRange/JobsRange";
import { format } from "date-fns";
import { MediaQueryContext } from "../../context/mediaQueryContext";
import clsx from "clsx";

const chipStyle = {
  fontSize: "1.2rem",
  height: "40px",
  paddingLeft: "10px",
  paddingRight: "10px",
  marginBottom: "5px",
  marginRight: "10px",
};

function CustomToolbar(
  from,
  showDelete,
  setShowDelete,
  jobsFilter,
  setJobsFilter,
  setShowRangeSelector,
  dateRange
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const jobsSearchActive = useSelector((state) => state.job.jobsSearchActive);

  // media queries
  const screenXL_DOWN = useMediaQuery(theme.breakpoints.down("xl"));
  const min1206 = useMediaQuery("(min-width:1206px)");
  const max600 = useMediaQuery("(max-width:600px)");
  const max1000 = useMediaQuery("(max-width:1165px)");
  // const max1000 = useMediaQuery("(max-width:1000px)");

  return (
    <div className="Table-toolbar relative" style={{ padding: "4px" }}>
      {from === "dashboard" || max600 === true ? null : (
        <>
          <Icon
            fontSize="large"
            className="Table-icon"
            sx={{
              "&.MuiIcon-root": {
                height: "1.5em",
              },
            }}
          >
            <ConstructionIcon fontSize="30px" style={{ color: "white" }} />
          </Icon>
          <div
            className="Table-icon-bg"
            style={{
              width: "116px",
              height: "116px",
              background: process.env.REACT_APP_PRIMARY_CLR_DARK,
            }}
          ></div>
        </>
      )}
      <Typography component="h1" variant="h6" color="#333333">
        {from === "dashboard" ? t("Today To Receive") : ""}
      </Typography>
      {from === "dashboard" ? null : (
        <div
          className={clsx("flex  justify-around gap-4", {
            "flex-col": max1000 === true,
            "w-full": max1000 === true,
          })}
        >
          <div
            className={clsx("w-full h-full flex flex-col justify-between", {
              "items-center": max1000 === true,
            })}
          >
            <Button
              size="small"
              style={{ fontSize: "16px", marginTop: "-10px", fontWeight: "bold" }}
              onClick={() => setShowRangeSelector(true)}
              startIcon={<DateRange />}
            >
              {format(dateRange.startDate, "dd.MM.yyyy")} -{format(dateRange.endDate, "dd.MM.yyyy")}
            </Button>
            {!max1000 && (
              <div style={{ display: "flex", alignItems: "start" }}>
                <Chip
                  size="small"
                  label={"Alle"}
                  key={"x"}
                  clickable
                  sx={chipStyle}
                  onClick={() => setJobsFilter("All")}
                  color={jobsFilter === "All" ? "primary" : "default"}
                />
                <Chip
                  size="small"
                  label={t("Pending")}
                  key={"y"}
                  clickable
                  sx={chipStyle}
                  onClick={() => setJobsFilter("pending")}
                  color={jobsFilter === "pending" ? "primary" : "default"}
                />
                <Chip
                  size="small"
                  label={t("Completed")}
                  key={"z"}
                  clickable
                  sx={chipStyle}
                  onClick={() => setJobsFilter("completed")}
                  color={jobsFilter === "completed" ? "primary" : "default"}
                />
                <Chip
                  size="small"
                  label={t("Estimation")}
                  key={"xy"}
                  clickable
                  sx={chipStyle}
                  onClick={() => setJobsFilter("estimation")}
                  color={jobsFilter === "estimation" ? "primary" : "default"}
                />
              </div>
            )}

            {max1000 && (
              <FormControl sx={{ width: "350px" }} size="small">
                <InputLabel id="demo-simple-select-label">Filters</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={jobsFilter}
                  label="Filters"
                  onChange={(event) => {
                    setJobsFilter(event.target.value);
                  }}
                >
                  <MenuItem value={"All"}>Alle</MenuItem>

                  <MenuItem value={"completed"}>{t("Completed")}</MenuItem>
                  <MenuItem value={"pending"}>{t("Pending")}</MenuItem>
                  <MenuItem value={"estimation"}>{t("Estimation")}</MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          <div
            className={clsx(` flex items-center gap-3`, {
              "flex-col": screenXL_DOWN === true,
            })}
          >
            <GridToolbarQuickFilter
              autoFocus={jobsSearchActive}
              variant="outlined"
              size="small"
              debounceMs={500}
              placeholder={t("Job No, Customer, Device...")}
              sx={{ width: max1000 ? "350px" : "300px" }}
            />
            <div className="flex items-center w-full">
              <Button
                size="small"
                fullWidth
                sx={{ fontSize: "1rem" }}
                startIcon={<Add />}
                variant="contained"
                onClick={() => dispatch(setBackdropVisible(true, "job wizard", "adding"))}
              >
                {t("Repair Job")}
              </Button>
              <IconButton fontSize={"large"} onClick={() => setShowDelete(!showDelete)}>
                <Delete fontSize={"large"} color="primary" />
              </IconButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const dateTimeOptions = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  timeZone: "Europe/Berlin",
};

export default function Jobs({ from }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const jobs = useSelector((state) => state.job.jobs);
  const [loading, setLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);

  const [jobsFilter, setJobsFilter] = useState("pending");
  const [filteredJobs, setFilteredJobs] = useState(jobs);

  const [showRangeSelector, setShowRangeSelector] = useState(false);
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [dateRange, setDateRange] = useState({
    startDate: thirtyDaysAgo,
    endDate: new Date(),
  });

  // console.log("job timestamp", new Date(1657384200 * 1000));
  // console.log("date compare", new Date() === new Date(1657384200 * 1000));
  // console.log("filteredJobs :>> ", filteredJobs);

  useEffect(() => {
    if (jobsFilter === "All") {
      setFilteredJobs(jobs);
    } else {
      if (from === "dashboard") {
        const today = new Date();
        setFilteredJobs(
          jobs.filter(
            (j) => new Date(j.returnDate.seconds * 1000).toDateString() === today.toDateString()
          )
        );
      } else {
        const filtered = jobs.filter((d) => d.status === jobsFilter);
        setFilteredJobs(filtered);
      }
    }

    return () => {};
  }, [jobsFilter, jobs]);

  useEffect(() => {
    let isMounted = true;
    const q = query(
      collection(fireStore, process.env.REACT_APP_SUBCOLLECTION_PATH + "jobs"),
      where("timestamp", ">=", dateRange.startDate, "timestamp", "<=", dateRange.endDate),
      orderBy("timestamp", "desc")
    );
    if (isMounted) {
      setLoading(true);
      onSnapshot(q, (querySnapshot) => {
        console.log("querySnapshot :>> ", querySnapshot);
        let jobs = [];
        querySnapshot.forEach((doc) => {
          jobs.push({ ...doc.data(), id: doc.id });
        });
        dispatch(setJobs(jobs));
        setLoading(false);
      });
    }
    // TimeAgo.addDefaultLocale(de)
    return () => {
      isMounted = false;
      console.log("jobs unmounted");
    };
  }, [dateRange]);

  const handleDeleteJob = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("row :>> ", params);
    dispatch(deleteJob(params.id));
  };

  const columns = [
    { field: "id", hide: true },
    {
      field: "jobNumber",
      headerName: t("Job No."),
      headerClassName: "table-header",
      width: 130,
    },
    {
      field: "timestamp",
      headerName: t("Date"),
      type: "dateTime",
      headerClassName: "table-header",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const dateFormatted = new Date(params.value.seconds * 1000).toLocaleDateString(
          "de-DE",
          dateTimeOptions
        );
        return dateFormatted;
      },
      valueParser: (value) => Number(value) / 100,
      width: 150,
    },

    {
      field: "customerName",
      headerName: t("Customer"),
      headerClassName: "table-header",
      width: 250,
    },
    {
      field: "deviceName",
      headerName: t("Device"),
      headerClassName: "table-header",
      editable: true,
      width: 300,
    },
    { field: "deviceIMEI", hide: true },
    {
      field: "status",
      headerName: t("Status"),
      headerClassName: "table-header",
      width: 100,
      renderCell: (params) => (
        <Chip
          variant={"outlined"}
          color={
            params.row.status === "pending"
              ? "warning"
              : params.row.status === "estimation"
              ? "primary"
              : "success"
          }
          label={
            params.row.status === "pending"
              ? t("Pending")
              : params.row.status === "estimation"
              ? t("Estimation")
              : t("Completed")
          }
        />
      ),
    },
    {
      field: "returnDate",
      headerName: t("Return Date"),
      type: "dateTime",
      width: 200,
      headerClassName: "table-header",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const dateFormatted = new Date(params.value.seconds * 1000).toLocaleDateString(
          "de-DE",
          dateTimeOptions
        );
        return dateFormatted;
      },
      valueParser: (value) => Number(value) / 100,
    },
    {
      field: "delete",
      headerName: t("Delete"),
      headerClassName: "table-header",
      width: 300,
      hide: !showDelete,
      renderCell: (params) => (
        <IconButton onClick={(e) => handleDeleteJob(e, params)}>
          <Delete color="error" />
        </IconButton>
      ),
    },
  ];

  const rows = filteredJobs.map((o, i) => ({
    id: o.id,
    jobNumber: o.jobNumber,
    timestamp: o.timestamp,
    customerName: o.customer.name,
    deviceName: o.device.name,
    deviceIMEI: o.device.serial,
    status: o.status,
    returnDate: new Date(o.returnDate.seconds * 1000).toLocaleDateString("de-DE", dateTimeOptions),
    ...o,
  }));

  const showJobDetails = (row) => {
    console.log("selected job", row.row);
    const job = {
      ...row.row,
    };
    delete job["customerName"];
    delete job["deviceName"];
    delete job["deviceIMEI"];

    dispatch(setCurrentJob(job));
    dispatch(setBackdropVisible(true, "job details", "viewing"));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: from === "dashboard" ? "100%" : "90%",
      }}
    >
      <div>
        <JobsRange
          setOrders={[]}
          dateRange={dateRange}
          setDateRange={setDateRange}
          showRangeSelector={showRangeSelector}
          setShowRangeSelector={setShowRangeSelector}
        />
      </div>
      {/* <div style={{ flexGrow: 1, background: "white" }}> */}
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [
              {
                field: from === "dashboard" ? "returnDate" : "timestamp",
                sort: from === "dashboard" ? "asc" : "desc",
              },
            ],
          },
        }}
        editMode="row"
        density="comfortable"
        loading={loading}
        rows={rows}
        columns={columns}
        headerHeight={65}
        pageSize={20}
        onRowClick={(row) => showJobDetails(row)}
        components={{
          Toolbar: () =>
            CustomToolbar(
              from,
              showDelete,
              setShowDelete,
              jobsFilter,
              setJobsFilter,
              setShowRangeSelector,
              dateRange
            ),
          LoadingOverlay: LinearProgress,
        }}
        sx={{
          minHeight: from === "dashboard" ? "unset" : "86vh",
          minWidth: "400px",
          padding: "10px",
          fontSize: "1.1rem",
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .table-header": {
            marginTop: "10px",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
            // boxShadow: "0px 0px 2px 3px #80808030",
            // margin: "10px",
          },
        }}
        localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
        //   rowsPerPageOptions={[5]}
        //   checkboxSelection
        disableSelectionOnClick
      />
      {/* </div> */}
    </div>
  );
}
