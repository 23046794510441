import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { imgStorage } from "../../firebase/config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Resizer from "react-image-file-resizer";

import { DummyInvoice } from "../Printings/DummyInvoice";
import { saveSettings, setCompanyDetails } from "../../Redux/Actions";
import { useState } from "react";
import { FileUpload, Lock } from "@mui/icons-material";

import { nanoid } from "nanoid";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const Input = styled("input")({
  display: "none",
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const formStyle = {
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  width: "80%",
  margin: "20px",
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    sx: { fontWeight: "bold", fontSize: "1rem" },
  };
}

export default function CompanyProfile({ setAdminAuthenticated }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const max700 = useMediaQuery("(max-width:700px)");

  const [value, setValue] = useState(0);
  const allSettings = useSelector((state) => state.company);
  const companyDetails = allSettings.companyDetails;
  const settingsLoading = allSettings.settingsLoading;
  const [changed, setChanged] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [logoUploading, setLogoUploading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);

  console.log("imgLoading :>> ", imgLoading);
  console.log("logoUploading :>> ", logoUploading);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputs = (attr, value) => {
    dispatch(setCompanyDetails(attr, value));
    setChanged(true);
  };

  // const fileTypes = ["image/png", "image/jpeg"];

  const logoUploadHandler = (img) => {
    setImgLoading(true);

    setFile(img);
    const fileName = nanoid() + img.image.name;
    const storageRef = ref(imgStorage, `/logo/${fileName}`);
    const metadata = {
      cacheControl: "max-age=2628288",
      customMetadata: {
        imageClass: "logo",
      },
    };
    const uploadTask = uploadBytesResumable(storageRef, img.image, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // let percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        //   setProgress(percentage);
      },
      (err) => {
        setError(err);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((URL) => handleInputs("logoUrl", URL));
        setLogoUploading(false);
      }
    );
    setError("");
    // } else {
    //   setError("Bitte wählen Sie eine Bilddatei aus (png or jpeg)");
    //   setFile(null);
    // }
  };

  const imageResizer = async (imageFile) => {
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        imageFile,
        250,
        250,
        imageFile.type === "image/png" ? "PNG" : "JPEG",
        50,
        0,
        (uri) => {
          // console.log("uri :>> ", uri);
          resolve(logoUploadHandler({ image: uri }));
        },
        "file"
      );
    });
  };

  const fileSelected = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    setLogoUploading(true);
    imageResizer(file);
  };

  const handleSaveSettings = () => {
    const settings = {
      ...allSettings,
    };
    delete settings.settingsLoading;
    delete settings.lastUpdated;
    dispatch(saveSettings(settings));
    setChanged(false);
  };

  return (
    <Paper sx={{ width: "100%", background: "white", padding: "20px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
          aria-label="basic tabs"
        >
          <Tab label={t("Company Profile")} {...a11yProps(0)} />
          <Tab label={t("Contact Details")} {...a11yProps(1)} />
          <Tab label={t("Bank Details")} {...a11yProps(2)} />
          <Tab label={t("More")} {...a11yProps(3)} />
        </Tabs>
        <div style={{ minWidth: "100px" }}>
          {settingsLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              size="large"
              disabled={!changed}
              onClick={() => handleSaveSettings()}
            >
              {t("Save")}
            </Button>
          )}
          <IconButton
            onClick={() => {
              setAdminAuthenticated(false);
              navigate("/");
            }}
            style={{ marginLeft: "20px" }}
            color="primary"
          >
            <Lock />
          </IconButton>
        </div>
      </Box>
      <div
        className={clsx("flex mt-[20px]", {
          "flex-col": max700 === true,
        })}
      >
        <div
          className={clsx("", {
            "w-[45%]": max700 === false,
            "w-full": max700 === true,
          })}
        >
          {value === 0 && (
            <div style={formStyle}>
              <TextField
                label={t("Company Name")}
                value={companyDetails.name}
                disabled={companyDetails.blockModification}
                onChange={(e) => handleInputs("name", e.target.value)}
                margin="normal"
              />
              <div style={{ display: "flex", width: "100%" }}>
                <TextField
                  label={t("Street")}
                  value={companyDetails.street}
                  disabled={companyDetails.blockModification}
                  onChange={(e) => handleInputs("street", e.target.value)}
                  margin="normal"
                  sx={{ width: "80%", marginRight: "15px" }}
                />
                <TextField
                  label={t("House No.")}
                  value={companyDetails.houseNo}
                  disabled={companyDetails.blockModification}
                  onChange={(e) => handleInputs("houseNo", e.target.value)}
                  margin="normal"
                />
              </div>
              <TextField
                label={t("Postal Code")}
                value={companyDetails.postalCode}
                disabled={companyDetails.blockModification}
                onChange={(e) => handleInputs("postalCode", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("City")}
                value={companyDetails.city}
                disabled={companyDetails.blockModification}
                onChange={(e) => handleInputs("city", e.target.value)}
                margin="normal"
              />
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{
                  margin: "20px 0",
                  padding: "15px",
                  border: "1px solid #c3c3c3",
                  borderRadius: "5px",
                }}
              >
                <span>{t("Company Logo")}</span>
                <label htmlFor="icon-button-file">
                  <Input
                    accept="image/png,image/jpeg"
                    id="icon-button-file"
                    multiple
                    type="file"
                    disabled={companyDetails.blockModification}
                    onChange={fileSelected}
                  />
                  <IconButton
                    size="large"
                    component="span"
                    disabled={companyDetails.blockModification}
                  >
                    <FileUpload
                      fontSize="large"
                      color={companyDetails.blockModification ? "disabled" : "primary"}
                    />
                  </IconButton>
                </label>
                {(logoUploading || imgLoading) && (
                  <Skeleton variant="rectangular" sx={{ paddingTop: "20%", width: "150px" }} />
                )}
                <div style={{ display: logoUploading || imgLoading ? "none" : "block" }}>
                  <img
                    src={companyDetails.logoUrl}
                    onLoad={() => setImgLoading(false)}
                    alt="logo-view"
                    width={150}
                  />
                </div>
              </Stack>
            </div>
          )}

          {value === 1 && (
            <div style={formStyle}>
              <TextField
                label={t("Phone")}
                value={companyDetails.phone}
                disabled={companyDetails.blockModification}
                onChange={(e) => handleInputs("phone", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("Email")}
                disabled={companyDetails.blockModification}
                value={companyDetails.email}
                onChange={(e) => handleInputs("email", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("Website")}
                disabled={companyDetails.blockModification}
                value={companyDetails.website}
                onChange={(e) => handleInputs("website", e.target.value)}
                margin="normal"
              />
            </div>
          )}

          {value === 2 && (
            <div style={formStyle}>
              <TextField
                label={t("Bank")}
                disabled={companyDetails.blockModification}
                value={companyDetails.bank}
                onChange={(e) => handleInputs("bank", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("Bank Account No.")}
                disabled={companyDetails.blockModification}
                value={companyDetails.accountNo}
                onChange={(e) => handleInputs("accountNo", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("Bank Code")}
                disabled={companyDetails.blockModification}
                value={companyDetails.bankCode}
                onChange={(e) => handleInputs("bankCode", e.target.value)}
                margin="normal"
              />
              <TextField
                label="IBAN"
                disabled={companyDetails.blockModification}
                value={companyDetails.iban}
                onChange={(e) => handleInputs("iban", e.target.value)}
                margin="normal"
              />
              <TextField
                label="BIC"
                disabled={companyDetails.blockModification}
                value={companyDetails.bic}
                onChange={(e) => handleInputs("bic", e.target.value)}
                margin="normal"
              />
            </div>
          )}
          {value === 3 && (
            <div style={formStyle}>
              <TextField
                label={t("Company Registeration Number")}
                disabled={companyDetails.blockModification}
                value={companyDetails.regNo}
                onChange={(e) => handleInputs("regNo", e.target.value)}
                margin="normal"
              />
              <TextField
                label="UID"
                disabled={companyDetails.blockModification}
                value={companyDetails.uid}
                onChange={(e) => handleInputs("uid", e.target.value)}
                margin="normal"
              />
              <TextField
                label="CEO"
                disabled={companyDetails.blockModification}
                value={companyDetails.ceo}
                onChange={(e) => handleInputs("ceo", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("Tax Title")}
                disabled={companyDetails.blockModification}
                value={companyDetails.taxTitle}
                onChange={(e) => handleInputs("taxTitle", e.target.value)}
                margin="normal"
              />
              <TextField
                label={t("Tax Percentage")}
                disabled={companyDetails.blockModification}
                value={companyDetails.taxPercent}
                type="number"
                sx={{ width: "30%" }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                onChange={(e) => handleInputs("taxPercent", e.target.valueAsNumber)}
                margin="normal"
              />
            </div>
          )}
          <Button
            fullWidth
            size="large"
            variant="contained"
            disabled={!changed}
            onClick={() => handleSaveSettings()}
          >
            {t("Save")}
          </Button>
        </div>
        <DummyInvoice />
      </div>
    </Paper>
  );
}
