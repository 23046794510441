import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import DevicesIcon from "@mui/icons-material/Devices";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import BadgeIcon from "@mui/icons-material/Badge";
import PrintIcon from "@mui/icons-material/Print";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { Divider, Paper, Typography, Button } from "@mui/material";
import { Badge, DeviceUnknown, ListAlt, NavigateBefore, NavigateNext } from "@mui/icons-material";

import DeviceType from "./WizardSteps/DeviceType";
import Model from "./WizardSteps/Model";
import DeviceDetails from "./WizardSteps/DeviceDetails";
import Customer from "./WizardSteps/Customer";
import Estimation from "./WizardSteps/Estimation";
import PrintInvoices from "./WizardSteps/PrintInvoices";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Employee from "./WizardSteps/Employee";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 32,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: process.env.REACT_APP_PRIMARY_CLR_LIGHT,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: process.env.REACT_APP_PRIMARY_CLR_MAIN,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: ownerState.completed || ownerState.active ? "#fff" : "#7a7a7a",
  width: 70,
  height: 70,
  display: "flex",
  borderRadius: "10%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: process.env.REACT_APP_PRIMARY_CLR_LIGHT,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.50)",
  }),
  ...(ownerState.completed && {
    backgroundColor: process.env.REACT_APP_PRIMARY_CLR_MAIN,
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  // const icons = {
  //   1: <DevicesIcon fontSize="large" />,
  //   2: <DeviceUnknown fontSize="large" />,
  //   3: <ListAlt fontSize="large" />,
  //   4: <DisplaySettingsIcon fontSize="large" />,
  //   5: <BadgeIcon fontSize="large" />,
  //   6: <PrintIcon fontSize="large" />,
  // };
  const icons = {
    1: <Badge fontSize="large" />,
    2: <DevicesIcon fontSize="large" />,
    3: <DeviceUnknown fontSize="large" />,
    4: <ListAlt fontSize="large" />,
    5: <DisplaySettingsIcon fontSize="large" />,
    6: <BadgeIcon fontSize="large" />,
    7: <PrintIcon fontSize="large" />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Contractor",
  "Device Type",
  "Model",
  "Quotation",
  "Device Details",
  "Contact",
  "Print",
];

export default function JobWizard() {
  const { t } = useTranslation();
  const currentAction = useSelector((state) => state.job.currentAction);
  const [activeStep, setActiceStep] = React.useState(currentAction === "editing" ? 2 : 0);
  const deviceName = useSelector((state) => state.job.currentJob.device.name);
  const currJob = useSelector((state) => state.job.currentJob);

  const customer = currJob.customer;
  console.log("activeStep", activeStep);
  return (
    <div>
      <Stack sx={{ width: "100%" }} spacing={4}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
          {steps.map((label, index) => (
            <Step
              key={label}
              onClick={() =>
                (currentAction === "editing" || index < activeStep) && setActiceStep(index)
              }
            >
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <div style={{ color: "white", fontWeight: "bold" }}>{t(label)}</div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <div style={{ margin: "30px 10px 30px 10px" }}>
        {/* <Typography variant="h4">Select model</Typography> */}
      </div>
      {activeStep === 0 ? <Employee setActiceStep={setActiceStep} /> : null}
      {activeStep === 1 ? <DeviceType setActiceStep={setActiceStep} /> : null}
      {activeStep === 2 ? <Model setActiceStep={setActiceStep} /> : null}
      {activeStep === 3 || activeStep === 4 || activeStep === 5 || activeStep === 6 ? (
        <div style={{ height: activeStep === 6 ? "unset" : "80vh", overflow: "overlay" }}>
          <Paper
            sx={
              activeStep === 6
                ? {
                    width: "100%",
                    margin: "auto",
                    padding: "0px",
                    backgroundColor: "transparent",
                    color: "white",
                  }
                : { width: "100%", margin: "auto", padding: "20px", backgroundColor: "#f6f6f6" }
            }
          >
            {activeStep === 6 ? null : (
              <Typography variant="h5" gutterBottom>
                {deviceName}
              </Typography>
            )}

            <Divider />
            {activeStep === 3 ? <Estimation /> : null}
            {activeStep === 4 ? <DeviceDetails /> : null}
            {activeStep === 5 ? <Customer /> : null}
            <Divider />

            {activeStep === 6 ? (
              <div style={{}}>
                <PrintInvoices setActiceStep={setActiceStep} activeStep={activeStep} />
              </div>
            ) : (
              <div className="wizard-nav">
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={<NavigateBefore />}
                  onClick={() => setActiceStep(activeStep - 1)}
                >
                  {t("Back")}
                </Button>
                <Button
                  disabled={
                    activeStep === 3
                      ? currJob.jobServices.length === 0 && currJob.extraItems.length === 0
                        ? true
                        : false
                      : activeStep === 5
                      ? customer.phone.length === "" ||
                        customer.name === "" ||
                        currJob.returnDate === null
                        ? true
                        : false
                      : false
                  }
                  variant="contained"
                  size="large"
                  endIcon={<NavigateNext />}
                  onClick={() => setActiceStep(activeStep + 1)}
                >
                  {t("Next")}
                </Button>
              </div>
            )}
          </Paper>
        </div>
      ) : null}
    </div>
  );
}
