import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import FunctionChecks from "./FunctionChecks";
import { Delete, ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const DeviceCard = (props) => {
  const { device } = props;
  const { t } = useTranslation();
  return (
    // max-w-[400px]
    <div className="p-2 sm:p-4 flex flex-col gap-2 w-[300px] sm:w-[500px] bg-[#ffffff42] rounded-lg  shadow-md">
      {/* TOP */}
      <div className="flex items-center justify-between ">
        <Typography fontWeight={"bold"}>{device?.name}</Typography>
        {/* <div>
          <IconButton size="small" disabled>
            <Delete color="error" />
          </IconButton>
        </div> */}
      </div>
      {/* BOTTOM */}
      <div>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {t("Function Checks")}
          </AccordionSummary>
          <AccordionDetails>
            <FunctionChecks params={device} />
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default DeviceCard;
