import { Close } from "@mui/icons-material";
import { Button, Divider, IconButton, Paper, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addBrand } from "../../../Redux/Actions";
import DeviceTypeSelection from "./DeviceTypeSelection";

import Logo from "./Logo";

function AddBrand({ from, setViewAddBrand }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [brand, setBrand] = useState({
    id: 0,
    name: "",
    logo: "",
    deviceTypes: [],
  });

  const handleAdd = () => {
    const data = {
      name: brand.name,
      logo: brand.logo,
      deviceTypes: brand.deviceTypes.map((d) => d.id),
    };
    dispatch(addBrand(data, from));
    if (from === "add device") {
      setViewAddBrand(false);
    }
  };
  return (
    <div>
      <Paper
        sx={{
          width: from === "add device" ? "100%" : "80%",
          margin: "50px auto",
          padding: "30px",
          backgroundColor: "#f6f6f6",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
          <Typography variant="h5" gutterBottom>
            {t("ADD NEW BRAND")}
          </Typography>
          {from === "add device" && (
            <IconButton onClick={() => setViewAddBrand(false)}>
              <Close fontSize="large" />
            </IconButton>
          )}
        </div>

        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            margin: "20px 0",
            padding: "15px",
            border: "1px solid #c3c3c3",
            borderRadius: "5px",
          }}
        >
          <span style={{ color: "#00000099" }}>{t("Logo")}</span>
          <Logo params={{ row: { ...brand } }} from={"adding"} setBrand={setBrand} brand={brand} />
        </Stack>
        <TextField
          label={t("Brand Name")}
          required
          fullWidth
          sx={{
            "& .MuiOutlinedInput-input": {
              fontSize: "1.2rem",
            },
          }}
          value={brand.name}
          onChange={(e) => setBrand({ ...brand, name: e.target.value })}
        />
        <div style={{ textAlign: "left", margin: "20px 0 10px 0", color: "#00000099" }}>
          {t("Device Types")}*
        </div>
        <div
          style={{
            margin: "10px 0 30px 0",
            padding: "15px",
            border: "1px solid #c3c3c3",
            borderRadius: "5px",
          }}
        >
          <DeviceTypeSelection setBrand={setBrand} brand={brand} />
        </div>
        <Divider />

        <div style={{ marginTop: "20px", display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={handleAdd}
            disabled={brand.name === "" || brand.deviceTypes.length === 0}
          >
            {t("Add Brand")}
          </Button>
        </div>
      </Paper>
    </div>
  );
}

export default AddBrand;
