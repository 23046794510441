import { Add, Close, Done } from "@mui/icons-material";
import { Chip, IconButton, InputAdornment, Stack, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addFunctionCheck, deleteFunctionCheck } from "../../../Redux/Actions";

const chipStyle = {
  fontSize: "1rem",
  height: "35px",
  paddingLeft: "10px",
  paddingRight: "10px",
  margin: "0 5px 10px 5px",
};
const chipsContainer = {
  display: "inline-flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  width: "95%",
};

const FunctionChecks = ({ params }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [adding, setAdding] = useState(false);
  const [rowId, setRowId] = useState(null);

  const [newCheck, setNewCheck] = useState("");

  const handleDelete = (check) => {
    dispatch(deleteFunctionCheck(params.id, check));
  };
  const handleAdding = (rowId) => {
    setRowId(rowId);
    setAdding(true);
  };

  const handleDone = () => {
    dispatch(addFunctionCheck(rowId, newCheck));
    setNewCheck("");
    setRowId(null);
    setAdding(false);
  };

  return (
    <div style={chipsContainer} tabIndex={params.id}>
      {/* {console.log("params", params)} */}
      {params.functionChecks.map((m, i) => (
        <Tooltip title={m} placement="top-start">
          <Chip
            label={m}
            key={i}
            clickable
            sx={chipStyle}
            onDelete={() => handleDelete(m)}
            // onClick={() => handleMultiSelection(m, "accessory")}
            color={"primary"}
          />
        </Tooltip>
      ))}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "5px",
          margin: "0 20px",
        }}
      >
        {adding && rowId === params.id ? (
          <TextField
            tabIndex={params.id}
            label={t("Function")}
            variant="standard"
            autoFocus
            // sx={{ width: "350px", mr: "10px" }}
            value={newCheck}
            onKeyDown={(e) => e.stopPropagation()}
            onChange={(e) => {
              setNewCheck(e.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      setAdding(false);
                      setRowId(null);
                    }}
                  >
                    <Close />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ) : null}

        <Chip
          className="w-full"
          label={adding ? t("Done") : t("Add")}
          key="99"
          clickable
          disabled={adding ? (newCheck === "" ? true : false) : false}
          onClick={() => (adding ? handleDone() : handleAdding(params.id))}
          sx={chipStyle}
          icon={adding ? <Done /> : <Add />}
          variant={"outlined"}
          color={adding ? "success" : "primary"}
        />
      </div>
    </div>
  );
};

export default FunctionChecks;
