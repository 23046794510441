import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getBrands, setBackdropVisible, updateBrand } from '../../../Redux/Actions';

import { DataGrid, GridToolbarFilterButton, deDE } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import '../../../App.css';
import { Button, Icon, IconButton, LinearProgress, useMediaQuery } from '@mui/material';
import { Add, BookmarkBorderOutlined, Delete, ReplayOutlined } from '@mui/icons-material';
import { useState } from 'react';
import BrandDeviceTypes from './BrandDeviceTypes';
import Logo from './Logo';
import { useTranslation } from 'react-i18next';

function CustomToolbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const max1024 = useMediaQuery('(max-width:1024px)');

  return (
    <div className="Table-toolbar">
      <Icon
        fontSize="large"
        className="Table-icon"
        sx={{
          '&.MuiIcon-root': {
            height: '1.5em',
          },
        }}
      >
        <BookmarkBorderOutlined fontSize="large" style={{ color: 'white', height: '1.5em' }} />
      </Icon>
      <div
        className="Table-icon-bg"
        style={{
          background: process.env.REACT_APP_PRIMARY_CLR_DARK,
        }}
      ></div>

      <div className="flex justify-start w-full max-w-[400px]">
        {/* <GridToolbarColumnsButton /> */}

        {/* <GridToolbarFilterButton sx={{ fontSize: "1rem" }} variant="outlined" /> */}
        <Button
          sx={{ fontSize: '1rem', marginLeft: '15px' }}
          startIcon={<Add />}
          variant="contained"
          fullWidth
          onClick={() => dispatch(setBackdropVisible(true, 'add brand', 'adding'))}
        >
          {t('Brand')}
        </Button>
        <IconButton
          fontSize={'large'}
          sx={{ marginLeft: '10px' }}
          onClick={() => dispatch(getBrands())}
        >
          <ReplayOutlined fontSize={'large'} color="primary" />
        </IconButton>
        {/* <IconButton
          fontSize={"large"}
          sx={{ marginLeft: "10px" }}
          onClick={() => setShowDelete(!showDelete)}
        >
          <Delete fontSize={"large"} color="primary" />
        </IconButton> */}
      </div>
    </div>
  );
}

export default function Brands(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.job.brands);
  const loading = useSelector((state) => state.job.brandsLoading);
  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteJob = (e, params) => {
    // e.preventDefault();
    // e.stopPropagation();
    // console.log("row :>> ", params);
    // dispatch(deleteJob(params.id));
  };

  const handleNameEdit = (data) => {
    console.log('data :>> ', data);
    console.log(data.value);

    dispatch(updateBrand(data.id, 'name', data.value));
  };

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'logo',
      headerName: t('Logo'),
      headerClassName: 'table-header-logo',
      width: 150,
      renderCell: (params) => <Logo params={params} from={'editing'} />,
    },
    {
      field: 'name',
      headerName: t('Brand Name'),
      headerClassName: 'table-header',
      editable: true,
      width: 250,
    },
    {
      field: 'deviceTypes',
      headerName: t('Device Type'),
      headerClassName: 'table-header',
      width: 800,
      renderCell: (params) => <BrandDeviceTypes params={params} />,
    },
    // {
    //   field: "delete",
    //   headerName: t("Delete"),
    //   headerClassName: "table-header",
    //   // width: 100,
    //   hide: !showDelete,
    //   renderCell: (params) => (
    //     <IconButton onClick={(e) => handleDeleteJob(e, params)}>
    //       <Delete color="error" />
    //     </IconButton>
    //   ),
    // },
  ];

  const rows = brands.map((o, i) => ({
    ...o,
  }));

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1, background: 'white' }}>
        <DataGrid
          density="comfortable"
          loading={loading}
          rows={rows}
          columns={columns}
          onCellEditCommit={(data) => handleNameEdit(data)}
          keyboard
          headerHeight={65}
          pageSize={20}
          disableSelectionOnClick
          //   onRowClick={(row) => showJobDetails(row)}
          components={{
            Toolbar: () => CustomToolbar(props, showDelete, setShowDelete),
            LoadingOverlay: LinearProgress,
          }}
          sx={{
            minWidth: '800px',
            minHeight: '90vh',
            padding: '15px',
            fontSize: '1.1rem',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .table-header': {
              marginTop: '10px',
            },
            '& .table-header-logo': {
              marginTop: '10px',
              textAlign: 'center',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row': {
              maxHeight: '300px !important',
            },

            '& .MuiDataGrid-cell': {
              maxHeight: '300px !important',
              padding: '10px',
              fontSize: '1.4rem',
            },
            '& .MuiDataGrid-editInputCell': {
              fontSize: '1.4rem',
            },
            // "& .MuiDataGrid-columnHeaderTitle": {
            //   fontWeight: "bold",

            // },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          //   rowsPerPageOptions={[5]}
          //   checkboxSelection
        />
      </div>
    </div>
  );
}
