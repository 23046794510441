import * as React from "react";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import Jobs from "../Jobs/Jobs";
import Cards from "./Cards";
import { motion } from "framer-motion";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      Ver 1.1.0&nbsp;&nbsp;|&nbsp;&nbsp;
      {"Copyright © "}
      <Link color="inherit" href="">
        Orionid Software Solutions
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const dateTimeOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  weekday: "long",
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
  timeZone: "Europe/Berlin",
};

export default function Dashboard() {
  const [value, setValue] = React.useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      <div className="flex flex-col gap-3">
        {/* <Grid container spacing={3}> */}
        {/* Chart */}
        {/* <Grid item sm={12} md={12} lg={12}> */}
        <Cards />
        {/* </Grid> */}

        {/* <Grid item xs={12} md={4} lg={3}>
          <motion.div
            style={{}}
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.6 }}
          >
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 246,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  height: "inherit",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Clock value={value} size={170} locale={"de-DE"} renderSecondHand={false} />
                </div>
                <div style={{ fontSize: "1rem" }}>
                  <Typography color="text.secondary">
                    {value.toLocaleDateString("de-DE", dateTimeOptions)}
                  </Typography>
                </div>
              </div>
            </Paper>
          </motion.div>
        </Grid> */}
        {/* Recent Orders */}
        {/* <Grid item xs={12} sm={12} md={12} lg={12}> */}
        <motion.div initial={{ y: 50 }} animate={{ y: 0 }} transition={{ duration: 0.5 }}>
          <Paper sx={{ p: 0, display: "flex", flexDirection: "column", height: 530 }}>
            <Jobs from="dashboard" />
          </Paper>
        </motion.div>
        {/* </Grid> */}
        {/* </Grid> */}
      </div>
      <Copyright sx={{ pt: 4 }} />
    </div>
  );
}
