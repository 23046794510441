import React, { useState } from "react";

import { imgStorage } from "../../../firebase/config";
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from "firebase/storage";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { Add, CameraAlt, Cancel, Close, Done, Error, FileUpload } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import TakePhotos from "./TakePhotos";
import { nanoid } from "nanoid";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { setDeviceAttributes } from "../../../Redux/Actions";
import { useTranslation } from "react-i18next";

const securityDB = ["No Password", "Password", "Pattern"];

const chipStyle = {
  fontSize: "1.2rem",
  height: "42px",
  paddingLeft: "10px",
  paddingRight: "10px",
  margin: "0 0 10px 10px",
};

const chipsContainer = {
  display: "inline-flex",
  flexWrap: "wrap",
  justifyContent: "flex-start",
  width: "100%",
  marginRight: "10px",
};

const Input = styled("input")({
  display: "none",
});

function DeviceDetailsAccordion(props) {
  const { t } = useTranslation();
  const { functionChecks, currDevice } = props;

  const dispatch = useDispatch();

  const [checks, setChecks] = useState(
    currDevice.defects.length > 0
      ? functionChecks.map((f) => ({ name: f, ok: currDevice.defects.includes(f) ? false : true }))
      : functionChecks.map((f) => ({ name: f, ok: true }))
  );
  const [security, setSecurity] = useState(t("No Password"));

  const [memories, setMemories] = useState(useSelector((state) => state.job.memories));
  const [accessories, setAccessoreis] = useState(useSelector((state) => state.job.accessories));
  const [addingNewMem, setAddingNewMem] = useState(false);
  const [newMem, setNewMem] = useState("");
  const [addingNewAcces, setAddingNewAcces] = useState(false);
  const [newAcces, setNewAcces] = useState("");

  const [files, setFiles] = useState([]);
  const [error, setError] = useState([]);
  const [progress, setProgress] = useState(0);

  const [imgUrls, setImgUrls] = useState(currDevice.photos);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadCompleted, setUploadCompleted] = useState(false);

  console.log("imgUrls :>> ", imgUrls);

  const [takingPic, setTakingPic] = useState(false);
  const [camImgs, setCamImgs] = useState([]);

  // console.log('camImgs', camImgs)

  // console.log("checks :>> ", checks);

  const [expanded, setExpanded] = useState("checks");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    dispatch(
      setDeviceAttributes(
        "defects",
        checks.filter((n) => !n.ok).map((f) => f.name)
      )
    );

    return () => {};
  }, [checks]);

  useEffect(() => {
    dispatch(setDeviceAttributes("photos", imgUrls));

    return () => {};
  }, [imgUrls]);

  const handleChecks = (event, index) => {
    const newChecks = checks.map((c, i) => {
      if (i === index) {
        return {
          ...c,
          ok: !c.ok,
        };
      } else return c;
    });
    setChecks(newChecks);
  };

  const handleNewCheck = (e, index) => {
    const newChecks = [...checks];
    newChecks[index].name = e.target.value;
    setChecks(newChecks);
  };

  const handleMultiSelection = (val, from) => {
    console.log("val :>> ", val);
    if (from === "accessory") {
      if (currDevice.accessories.includes(val)) {
        dispatch(
          setDeviceAttributes(
            "accessories",
            currDevice.accessories.filter((a) => a !== val)
          )
        );
      } else {
        dispatch(setDeviceAttributes("accessories", [...currDevice.accessories, val]));
      }
    }
  };

  const fileChangeHandler = (e) => {
    let selectedFiles = [...e.target.files];
    setFiles(selectedFiles);

    console.log("selectedFiles :>> ", selectedFiles);
    setUploadLoading(true);
    const promises = [];
    if (selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        const fileName = nanoid() + file.name;
        const metadata = {
          cacheControl: "max-age=2628288",
          customMetadata: {
            imageClass: "device-photo",
          },
        };
        const storageRef = ref(imgStorage, `/Images/${fileName}`);

        const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        promises.push(uploadTask);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            let percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            setProgress(percentage);
          },
          (err) => {
            setError(err);
          },
          async () => {
            await getDownloadURL(uploadTask.snapshot.ref).then((URL) =>
              setImgUrls((prevState) => [...prevState, URL])
            );
          }
        );
        setProgress(0);
      });
      Promise.all(promises)
        .then(() => {
          setUploadCompleted(true);
          setUploadLoading(false);
          console.log("All images uploaded");
        })
        .then((err) => console.log(err));

      setError("");
    } else {
      setError("Bitte wählen Sie eine Bilddatei aus (png or jpeg)");
      setFiles(null);
    }
  };

  const camImgUploadHandler = () => {
    setUploadLoading(true);
    const promises = [];
    if (camImgs.length > 0) {
      camImgs.forEach((img) => {
        const fileName = nanoid() + ".jpg";
        const storageRef = ref(imgStorage, `/Images/${fileName}`);
        // const uploadTask = uploadBytes(storageRef, camImg);
        promises.push(
          uploadString(storageRef, img.toString(), "data_url").then(
            (snapshot) => {
              getDownloadURL(snapshot.ref).then((URL) =>
                setImgUrls((prevState) => [...prevState, URL])
              );
            },
            (err) => {
              setError(err);
            }
          )
        );
        setError("");
      });
      Promise.all(promises)
        .then(() => {
          setUploadCompleted(true);
          setUploadLoading(false);
          console.log("All images uploaded");
        })
        .then((err) => console.log(err));
    } else {
      setError("Bitte wählen Sie eine Bilddatei aus (png or jpeg)");
    }
  };

  const handleRemoveUploadedImage = (index, camImg) => {
    if (camImg) {
      if (camImgs.length === 1) setUploadCompleted(false);
      setCamImgs(camImgs.filter((c, i) => i !== index));
    } else {
      if (files.length === 1) setUploadCompleted(false);

      setFiles(files.filter((f, i) => i !== index));
    }
    setImgUrls(imgUrls.filter((u, i) => i !== index));
    // setCamImgs(null);
    // setTakingPic(false);
  };

  return (
    <div style={{ marginTop: "20px", display: "inline-flex", alignItems: "flex-start" }}>
      <div style={{ width: "50%", marginRight: "30px" }}>
        <Accordion
          expanded={expanded === "checks"}
          onChange={handleChange("checks")}
          sx={
            expanded === "checks"
              ? { border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}` }
              : null
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="checksbh-content"
            id="checksbh-header"
            sx={{ padding: "8px 16px" }}
          >
            <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: "bold" }} variant={"h6"}>
              {t("Defects")}
            </Typography>
            <Stack direction="row" spacing={1}>
              {currDevice.defects.map((d, i) =>
                d.name === "" ? null : <Chip label={d} color="primary" key={i} />
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: "flex", marginBottom: "10px", color: "grey" }}>
              <div style={{ width: "31%" }}>
                <Typography
                  variant="overline"
                  sx={{ color: "#00000099", fontSize: "0.8rem" }}
                  gutterBottom
                >
                  {t("Function Checks")}
                </Typography>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
              {checks.map((c, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #f3f3f3",
                    padding: "5px 0 5px 0",
                  }}
                >
                  <div style={{ width: "40%" }}>
                    {c.new ? (
                      <TextField
                        variant="standard"
                        value={c.name}
                        onChange={(e) => handleNewCheck(e, i)}
                        sx={{ fontSize: "1.2rem" }}
                      />
                    ) : (
                      <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                        {c.name}
                      </Typography>
                    )}
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {/* <Radio
                      onChange={(e) => handleChecks(e, i)}
                      checked={c.ok}
                      name="ok-radio-buttons"
                    />
                    <Radio
                      onChange={(e) => handleChecks(e, i)}
                      color="secondary"
                      checked={!c.ok}
                      name="defected-radio-buttons"
                    /> */}
                    <ButtonGroup>
                      <Button
                        startIcon={c.ok && <Done />}
                        variant={c.ok ? "outlined" : "contained"}
                        onClick={c.ok ? null : (e) => handleChecks(e, i)}
                        color={c.ok ? "success" : "inherit"}
                      >
                        {t("OK")}
                      </Button>
                      <Button
                        startIcon={!c.ok && <Error />}
                        variant={c.ok ? "contained" : "outlined"}
                        onClick={c.ok ? (e) => handleChecks(e, i) : null}
                        color={c.ok ? "inherit" : "error"}
                      >
                        {t("Defected")}
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              ))}
              <br />
              <Button
                fullWidth
                onClick={() =>
                  setChecks([
                    ...checks,
                    {
                      name: "",
                      ok: false,
                      new: true,
                    },
                  ])
                }
                startIcon={<Add />}
              >
                {t("Add New Check")}
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "memory"}
          onChange={handleChange("memory")}
          sx={
            expanded === "memory"
              ? { border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}` }
              : null
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="memorybh-content"
            id="memorybh-header"
            sx={{ padding: "4px 16px" }}
          >
            <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: "bold" }} variant={"h6"}>
              {t("Memory")}
            </Typography>
            {currDevice.memory !== "" ? <Chip label={currDevice.memory} color="primary" /> : null}
          </AccordionSummary>
          <AccordionDetails>
            <div style={chipsContainer}>
              {memories.map((m, i) => (
                <Chip
                  label={m}
                  key={i}
                  clickable
                  icon={currDevice.memory === m ? <Close /> : null}
                  sx={chipStyle}
                  onClick={() => {
                    currDevice.memory === m
                      ? dispatch(setDeviceAttributes("memory", ""))
                      : dispatch(setDeviceAttributes("memory", m));
                  }}
                  color={m === currDevice.memory ? "primary" : "default"}
                />
              ))}

              <div style={{ display: "flex", alignItems: "flex-end", marginLeft: "10px" }}>
                {addingNewMem ? (
                  <TextField
                    label={t("New Memory")}
                    variant="outlined"
                    margin="dense"
                    sx={{ width: "80%", marginRight: "10px" }}
                    value={newMem}
                    onChange={(e) => {
                      setNewMem(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setAddingNewMem(false);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : null}
                <Chip
                  label={addingNewMem ? t("Done") : t("Add")}
                  key="99"
                  clickable
                  disabled={addingNewMem ? (newMem.length < 1 ? true : false) : false}
                  onClick={
                    addingNewMem
                      ? () => {
                          setMemories([...memories, newMem]);
                          setAddingNewMem(false);
                        }
                      : () => setAddingNewMem(true)
                  }
                  sx={chipStyle}
                  icon={addingNewMem ? <Done /> : <Add />}
                  variant={"outlined"}
                  color={addingNewMem ? "success" : "primary"}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "accessories"}
          onChange={handleChange("accessories")}
          sx={
            expanded === "accessories"
              ? { border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}` }
              : null
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accessoriesbh-content"
            id="accessoriesbh-header"
            sx={{ padding: "8px 16px" }}
          >
            <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: "bold" }} variant={"h6"}>
              {t("Accessories")}
            </Typography>
            <Stack direction="row" spacing={1}>
              {currDevice.accessories.map((a, i) => (
                <Chip label={a} color="primary" key={i} />
              ))}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <div style={chipsContainer}>
              {accessories.map((m, i) => (
                <Chip
                  label={m}
                  key={i}
                  icon={currDevice.accessories.includes(m) ? <Close /> : null}
                  clickable
                  sx={chipStyle}
                  onClick={() => handleMultiSelection(m, "accessory")}
                  color={currDevice.accessories.includes(m) ? "primary" : "default"}
                />
              ))}

              <div style={{ display: "flex", alignItems: "flex-end", marginLeft: "10px" }}>
                {addingNewAcces ? (
                  <TextField
                    label={t("New Accessory")}
                    variant="outlined"
                    margin="dense"
                    sx={{ width: "80%", marginRight: "10px" }}
                    value={newAcces}
                    onChange={(e) => {
                      setNewAcces(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setAddingNewAcces(false);
                            }}
                          >
                            <Close />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : null}
                <Chip
                  label={addingNewAcces ? t("Done") : t("Add")}
                  key="99"
                  clickable
                  disabled={addingNewAcces ? (newAcces.length < 1 ? true : false) : false}
                  onClick={
                    addingNewAcces
                      ? () => {
                          setAccessoreis([...accessories, newAcces]);
                          setAddingNewAcces(false);
                        }
                      : () => setAddingNewAcces(true)
                  }
                  sx={chipStyle}
                  icon={addingNewAcces ? <Done /> : <Add />}
                  variant={"outlined"}
                  color={addingNewAcces ? "success" : "primary"}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div style={{ width: "50%" }}>
        <Accordion
          expanded={expanded === "security"}
          onChange={handleChange("security")}
          sx={
            expanded === "security"
              ? { border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}` }
              : null
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="securitybh-content"
            id="securitybh-header"
            sx={{ padding: "8px 16px" }}
          >
            <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: "bold" }} variant={"h6"}>
              {t("Device Security")}
            </Typography>
            {security === "Password" || security === "Pattern" ? (
              <Chip label={security + ": " + currDevice.password} color="primary" />
            ) : (
              <Chip label={security} color="primary" />
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" spacing={2} flexWrap="wrap" justifyContent="center">
              {securityDB.map((m, i) => (
                <Chip
                  label={t(m)}
                  key={i}
                  clickable
                  sx={chipStyle}
                  onClick={() => {
                    setSecurity(m);
                    dispatch(setDeviceAttributes("password", ""));
                  }}
                  color={security === m ? "primary" : "default"}
                />
              ))}
            </Stack>
            {security === "Password" ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <TextField
                  label={t(security)}
                  variant="outlined"
                  value={currDevice.password}
                  onChange={(e) => dispatch(setDeviceAttributes("password", e.target.value))}
                />
              </div>
            ) : security === "Pattern" ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <TextField
                  label={t(security)}
                  variant="outlined"
                  type={"number"}
                  value={currDevice.password}
                  onChange={(e) => dispatch(setDeviceAttributes("password", e.target.value))}
                />
              </div>
            ) : null}
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "photos"}
          onChange={handleChange("photos")}
          sx={
            expanded === "photos"
              ? { border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}` }
              : null
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="photos-content"
            id="photos-header"
            sx={{ padding: "8px 16px" }}
          >
            <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: "bold" }} variant={"h6"}>
              {t("Device Photos")}
            </Typography>
            <Stack direction="row" spacing={1}>
              {imgUrls.length > 0 ? (
                <Chip label={imgUrls.length + t(" Photos")} color="primary" />
              ) : null}
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack direction="row" alignItems="center" spacing={2}>
              <span>Bild hinzufügen *</span>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/png,image/jpeg"
                  id="icon-button-file"
                  multiple
                  type="file"
                  onChange={fileChangeHandler}
                  disabled={imgUrls.length > 0 || camImgs.length > 0}
                />
                <IconButton
                  size="large"
                  component="span"
                  disabled={imgUrls.length > 0 || camImgs.length > 0}
                >
                  <FileUpload
                    fontSize="large"
                    color={imgUrls.length > 0 || camImgs.length > 0 ? "default" : "primary"}
                  />
                </IconButton>
              </label>
              <IconButton
                size="large"
                onClick={() => setTakingPic(true)}
                disabled={imgUrls.length > 0}
              >
                <CameraAlt fontSize="large" color={imgUrls.length > 0 ? "deffault" : "primary"} />
              </IconButton>
            </Stack>
            {uploadCompleted && files.length > 0
              ? files.map((file, i) => (
                  <div style={{ textAlign: "left" }} key={i}>
                    {file.name}{" "}
                    <Cancel
                      onClick={() => handleRemoveUploadedImage(i, false)}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                ))
              : null}
            {takingPic || camImgs.length > 0 ? (
              <TakePhotos
                takingPic={takingPic}
                setTakingPic={setTakingPic}
                camImgs={camImgs}
                setCamImgs={setCamImgs}
                camImgUploadHandler={camImgUploadHandler}
                uploadCompleted={uploadCompleted}
                handleRemoveUploadedImage={handleRemoveUploadedImage}
                uploadLoading={uploadLoading}
              />
            ) : null}
            {uploadLoading ? (
              <Box style={{ width: "100%", textAlign: "center" }}>
                <p>bitte warten...</p>
                <Skeleton animation="wave" />
              </Box>
            ) : null}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default DeviceDetailsAccordion;
