import React, { useEffect, useRef } from "react";

import { Invoice } from "../../Printings/Invoice";
import { useReactToPrint } from "react-to-print";
import { Button, ButtonGroup, IconButton } from "@mui/material";
import { Beenhere, HistoryEdu, NavigateBefore, Print, Save, Update } from "@mui/icons-material";
import { Label } from "../../Printings/Label";
import { useDispatch, useSelector } from "react-redux";
import { setJobNumber, updateJob } from "../../../Redux/Actions";
import { addJob } from "../../../Redux/Actions";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EstimationInvoice } from "../../Printings/EstimationInvoice";
import SignatureDialog from "../Utils/SignaturesDialog";

const invoiceStyle = `
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 5mm;
}

@media screen {
  div.divFooter {
    /* display: none; */
  }
}
`;

const labelStyle = `
@media print {
  @page { size: 375px 185px; margin: 0;}
}
`;

const printIconLabel = {
  margin: "0 0px -46px 210px",
  position: "relative",
  zIndex: 10,
};
const printIconInvoice = {
  textAlign: "right",
  margin: "0 10px -46px 0px",
  position: "relative",
  zIndex: 10,
};

const generateJobNumber = () => {
  const today = new Date();
  const serialdate = today.getDate().toString() + (today.getMonth() + 1);
  const serialtime =
    today.getHours().toString() +
    today.getMinutes().toString() +
    today.getSeconds().toString() +
    today.getMilliseconds().toString().slice(0, 1);
  const serialstamp = serialdate + " " + serialtime;
  const serialTimeDate = serialstamp.replace(/[^0-9]/g, "");

  // const name = customerName;
  // let initials = name.match(/\b\w/g) || [];
  // initials = ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();

  const serial = serialTimeDate;
  return serial;
};

function PrintInvoices(props) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentJob = useSelector((state) => state.job.currentJob);
  const currentAction = useSelector((state) => state.job.currentAction);

  const [invoiceType, setInvoiceType] = React.useState("general");
  const [signatureDialogVisible, setSignatureDialogVisible] = React.useState(false);
  const { setActiceStep, activeStep } = props;

  const [estimationOnly, setEstimationOnly] = React.useState(false);

  const invoiceRef = useRef();
  const custommerInvoiceRef = useRef();
  const labelRef = useRef();
  const estimationInvoiceRef = useRef();

  useEffect(() => {
    if (currentAction !== "editing") {
      const jobNum = generateJobNumber(currentJob.customer.name);
      dispatch(setJobNumber(jobNum));
    }
    return () => {};
  }, []);

  const handleFinish = () => {
    dispatch(addJob(currentJob, false));
    navigate("/jobs", { replace: true });
  };

  const handleFinishEstimation = () => {
    dispatch(addJob(currentJob, true));
    navigate("/jobs", { replace: true });
  };

  const handleUpdate = () => {
    dispatch(updateJob(currentJob));
  };

  const handlePrintLabel = useReactToPrint({
    content: () => labelRef.current,
    pageStyle: labelStyle,
    onAfterPrint: () => (currentAction === "editing" ? handleUpdate() : handleFinish()),
  });
  const handlePrintLabelOnly = useReactToPrint({
    content: () => labelRef.current,
    pageStyle: labelStyle,
  });

  const printCustomerInvoice = useReactToPrint({
    content: () => custommerInvoiceRef.current,
    pageStyle: invoiceStyle,
    onAfterPrint: () => handlePrintLabel(),
  });

  const handlePrintInvoice = useReactToPrint({
    content: () => invoiceRef.current,
    pageStyle: invoiceStyle,
  });

  const handlePrintEstimationInvoice = useReactToPrint({
    content: () => estimationInvoiceRef.current,
    pageStyle: invoiceStyle,
    onAfterPrint: () => handleFinishEstimation(),
  });

  const handlePrintAndSave = useReactToPrint({
    content: () => invoiceRef.current,
    pageStyle: invoiceStyle,
    onAfterPrint: () => printCustomerInvoice(),
  });

  const handleTakeSignature = () => {
    document.documentElement.requestFullscreen();
    setSignatureDialogVisible(true);
  };

  return (
    <div>
      <div
        style={{
          margin: "10px auto 30px auto",
          width: "90%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="outlined"
          size="large"
          startIcon={<NavigateBefore />}
          onClick={() => setActiceStep(activeStep - 1)}
        >
          {t("Back")}
        </Button>
        {currentAction === "editing" ? (
          <div>
            <Button
              variant="contained"
              size="large"
              endIcon={<Update />}
              sx={{ marginRight: "10px" }}
              onClick={handleUpdate}
            >
              {t("Update")}
            </Button>
            <Button
              variant="contained"
              size="large"
              endIcon={<Print />}
              onClick={handlePrintAndSave}
            >
              {t("Update and Print")}
            </Button>
          </div>
        ) : (
          <div className="flex flex-col xl:flex-row gap-2">
            <Button
              variant="contained"
              size="large"
              endIcon={<Save />}
              color="secondary"
              onClick={() => {
                setEstimationOnly(true);
                handlePrintEstimationInvoice();
              }}
            >
              {t("Save as Cost Estimation")}
            </Button>
            {/* <Button
              variant="contained"
              size="large"
              color="success"
              endIcon={<Beenhere />}
              onClick={handleFinish}
              sx={{ marginRight: "10px" }}
            >
              {t("Book repair")}
            </Button> */}
            <Button
              variant="contained"
              color="success"
              size="large"
              endIcon={<HistoryEdu />}
              onClick={handleTakeSignature}
            >
              {t("Take Signatures")}
            </Button>
            <Button
              variant="contained"
              color="success"
              size="large"
              endIcon={<Beenhere />}
              onClick={handlePrintAndSave}
            >
              {t("Book repair and Print")}
            </Button>
          </div>
        )}
      </div>
      <div style={{ width: "60%" }}>
        {t("Preview")}&nbsp;&nbsp;
        <ButtonGroup color="secondary">
          <Button
            variant={invoiceType === "general" ? "outlined" : "contained"}
            onClick={() => setInvoiceType("general")}
          >
            {t("General")}
          </Button>
          <Button
            variant={invoiceType === "general" ? "contained" : "outlined"}
            onClick={() => setInvoiceType("estimation")}
          >
            {t("Estimation")}
          </Button>
        </ButtonGroup>
      </div>

      <div
        className="flex xl:flex-row flex-col gap-2"
        style={{ margin: "0px auto 30px auto", width: "91%" }}
      >
        <div>
          {currentAction === "editing" && (
            <div style={printIconInvoice}>
              <IconButton onClick={handlePrintInvoice}>
                <Print color="primary" />
              </IconButton>
            </div>
          )}

          {invoiceType === "general" ? <Invoice ref={invoiceRef} /> : <EstimationInvoice />}
          <div style={{ display: "none" }}>
            <Invoice ref={custommerInvoiceRef} />
            <EstimationInvoice ref={estimationInvoiceRef} />
          </div>
        </div>
        <div style={{ margin: "0px 20px 20px 20px", width: "100%" }}>
          {currentAction === "editing" && (
            <div style={printIconLabel}>
              <IconButton onClick={handlePrintLabelOnly}>
                <Print color="primary" />
              </IconButton>
            </div>
          )}
          <Label ref={labelRef} />
        </div>
      </div>
      <SignatureDialog
        signatureDialogVisible={signatureDialogVisible}
        setSignatureDialogVisible={setSignatureDialogVisible}
      />
    </div>
  );
}

export default PrintInvoices;
