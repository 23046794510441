import * as React from "react";

import { styled } from "@mui/material/styles";

import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

import DrawerList from "./DrawerList";
import AdminList from "./AdminList";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import { useMediaQuery } from "@mui/material";

const DrawerCustom = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: 240,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function SideDrawer(props) {
  const logoUrl = useSelector((state) => state.company.companyDetails.logoUrl);
  const { open, toggleDrawer } = props;
  const max780 = useMediaQuery("(max-width:780px)");

  const drawerContent = (
    <>
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          px: [1],
        }}
      >
        <div style={{ width: "90%" }}>
          <img src={logoUrl} alt="logo-bar" width={80} />
        </div>
        <IconButton onClick={toggleDrawer} sx={{ width: "10%" }}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        {<DrawerList />}
        <Divider sx={{ my: 1 }} />
      </List>
      <List component="nav">
        {<AdminList />}
        <Divider sx={{ my: 3 }} />
      </List>
    </>
  );

  return (
    <>
      {max780 ? (
        <Drawer onClose={toggleDrawer} open={open}>
          {drawerContent}
        </Drawer>
      ) : (
        <DrawerCustom variant="permanent" open={open}>
          {drawerContent}
        </DrawerCustom>
      )}
    </>
  );
}

export default SideDrawer;
