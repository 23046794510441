import {
  Add,
  Close,
  Computer,
  DesktopMac,
  DesktopWindows,
  DevicesOther,
  ExpandMore,
  LaptopMac,
  PhoneIphone,
  Tablet,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addDevice, setModelSearchInput, updateDevice } from '../../../Redux/Actions';
import AddBrand from '../Brands/AddBrand';

const chipsContainer = {
  padding: '20px 5px 10px 5px',
  borderRadius: '5px',
  display: 'inline-flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '90%',
};

const chipStyle = {
  fontSize: '1.2rem',
  height: '45px',
  paddingLeft: '15px',
  paddingRight: '15px',
  margin: '0 10px 10px 10px',
};

const icons = (index) => {
  switch (index) {
    case 0:
      return <PhoneIphone />;
    case 1:
      return <Tablet />;
    case 2:
      return <DesktopMac />;
    case 3:
      return <LaptopMac />;
    case 4:
      return <DesktopWindows />;
    case 5:
      return <Computer />;
    case 6:
      return <DevicesOther />;

    default:
      break;
  }
};

function DeviceActions({ from, setViewAddDevice, modelName, setNewDevice }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deviceTypes = useSelector((state) => state.job.deviceTypes);
  const currDeviceType = useSelector((state) => state.job.currDeviceType);
  const brands = useSelector((state) => state.job.brands);
  const currentAction = useSelector((state) => state.job.currentAction);
  const deviceToEdit = useSelector((state) => state.job.deviceToEdit);
  const max1024 = useMediaQuery('(max-width:1024px)');

  const brandsLoading = useSelector((state) => state.job.brandsLoading);

  const [viewAddBrand, setViewAddBrand] = useState(false);

  const [expanded, setExpanded] = useState(
    currentAction === 'editing device' ? null : from === 'job wizard' ? 'brand' : 'deviceType'
  );
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [device, setDevice] = useState(
    currentAction === 'editing device'
      ? deviceToEdit
      : {
          brand: '',
          model: from === 'job wizard' ? modelName : '',
          deviceType: from === 'job wizard' ? currDeviceType : '',
          repairServices: [],
        }
  );
  console.log('device :>> ', device);

  useEffect(() => {
    if (device.deviceType !== '' && currentAction !== 'editing device') {
      const checks = device.deviceType.functionChecks;
      const services = checks.map((c) => ({
        name: c,
        price: 0,
      }));
      setDevice({ ...device, repairServices: services });
    }

    return () => {};
  }, [device.deviceType]);

  const handleAdd = () => {
    const data = {
      model: device.model,
      deviceTypeId: device.deviceType.id,
      brandId: device.brand.id,
      repairServices: device.repairServices,
    };
    dispatch(addDevice(data, from === 'job wizard' ? false : true, currDeviceType));
    if (from === 'job wizard') {
      setNewDevice(device.model);
      dispatch(setModelSearchInput(device.model));
      setViewAddDevice(false);
    }
  };

  const handleUpdate = () => {
    const data = {
      model: device.model,
      deviceTypeId: device.deviceType.id,
      brandId: device.brand.id,
      repairServices: device.repairServices,
    };
    dispatch(updateDevice(deviceToEdit.id, data));
  };

  const handleServiceInputs = (e, service, index, attribute) => {
    const newServices = device.repairServices.map((s, i) => {
      if (i === index) {
        return {
          ...service,
          [attribute]: attribute === 'name' ? e.target.value : e.target.valueAsNumber,
        };
      } else return s;
    });
    setDevice({ ...device, repairServices: newServices });
  };

  return (
    <div>
      <Paper
        sx={{
          width: max1024 ? '100%' : '95%',
          margin: '50px auto',
          padding: '30px',
          backgroundColor: '#f6f6f6',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Typography variant="h5" gutterBottom>
            {currentAction === 'editing device' ? t('UPDATE DEVICE') : t('ADD NEW DEVICE')}
          </Typography>
          {from === 'job wizard' && (
            <IconButton onClick={() => setViewAddDevice(false)}>
              <Close fontSize="large" />
            </IconButton>
          )}
        </div>

        <Divider sx={{ margin: '20px 0' }} />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '49%' }}>
            <Accordion
              disabled={from === 'job wizard'}
              expanded={expanded === 'deviceType'}
              onChange={handleChange('deviceType')}
              sx={
                expanded === 'deviceType'
                  ? { border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}` }
                  : null
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="deviceType-content"
                id="deviceType-header"
                sx={{ padding: '4px 16px' }}
              >
                <Typography
                  sx={{ width: '25%', flexShrink: 0, color: '#00000099', textAlign: 'left' }}
                  variant={'h6'}
                >
                  {t('Device Type')}*
                </Typography>
                {device.deviceType !== '' ? (
                  <Chip label={device.deviceType.name} color="primary" />
                ) : null}
              </AccordionSummary>
              <AccordionDetails>
                <div style={chipsContainer}>
                  {deviceTypes.map((d, i) => (
                    <Chip
                      label={d.name}
                      key={i}
                      clickable
                      icon={icons(i)}
                      sx={chipStyle}
                      onClick={() => setDevice({ ...device, deviceType: d })}
                      color={device.deviceType.id === d.id ? 'primary' : 'default'}
                    />
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === 'brand'}
              onChange={handleChange('brand')}
              sx={
                expanded === 'brand'
                  ? { border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}` }
                  : null
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="brand-content"
                id="brand-header"
                sx={{ padding: '4px 16px' }}
              >
                <Typography
                  sx={{ width: '25%', flexShrink: 0, color: '#00000099', textAlign: 'left' }}
                  variant={'h6'}
                >
                  {t('Brand')}*
                </Typography>
                {device.brand !== '' ? <Chip label={device.brand.name} color="primary" /> : null}
              </AccordionSummary>
              <AccordionDetails>
                {brandsLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <div style={chipsContainer}>
                      {brands.map((b, i) => (
                        <Chip
                          label={b.name}
                          key={i}
                          clickable
                          avatar={<Avatar alt={b.name} src={b.logo} />}
                          sx={chipStyle}
                          onClick={() => setDevice({ ...device, brand: b })}
                          color={device.brand.id === b.id ? 'primary' : 'default'}
                        />
                      ))}
                      <Chip
                        label={t('Add New')}
                        key={999}
                        clickable
                        variant="outlined"
                        icon={<Add />}
                        sx={chipStyle}
                        onClick={() => setViewAddBrand(true)}
                        color="primary"
                      />
                    </div>
                    {viewAddBrand && (
                      <AddBrand from="add device" setViewAddBrand={setViewAddBrand} />
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>

            <TextField
              label={t('Device Model')}
              fullWidth
              Required
              sx={{
                '& .MuiOutlinedInput-input': {
                  fontSize: '1.2rem',
                  background: 'white',
                },
                margin: '30px 0',
              }}
              value={device.model}
              onChange={(e) => setDevice({ ...device, model: e.target.value })}
            />
          </div>
          <div style={{ width: '49%' }}>
            <Accordion
              expanded={true}
              onChange={handleChange('repairServices')}
              sx={
                expanded === 'repairServices'
                  ? { border: `1px solid ${process.env.REACT_APP_PRIMARY_CLR_MAIN}` }
                  : null
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="repairServices-content"
                id="repairServices-header"
                sx={{ padding: '4px 16px' }}
              >
                <Typography
                  sx={{ width: '50%', flexShrink: 0, color: '#00000099', textAlign: 'left' }}
                  variant={'h6'}
                >
                  {t('Repair Services')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {device.repairServices.map((r, i) => (
                  <div style={{ display: 'flex', justifyConent: 'space-between' }} key={i}>
                    <TextField
                      label={t('Service')}
                      value={r.name}
                      margin="dense"
                      sx={{ '& .MuiFilledInput-input': { fontSize: '1.2rem' }, width: '75%' }}
                      fullWidth
                      onChange={(e) => handleServiceInputs(e, r, i, 'name')}
                    />
                    <TextField
                      label={t('Price')}
                      value={r.price}
                      margin="dense"
                      sx={{ '& .MuiFilledInput-input': { fontSize: '1.2rem' }, width: '25%' }}
                      fullWidth
                      type={'number'}
                      onChange={(e) => handleServiceInputs(e, r, i, 'price')}
                    />
                    <IconButton
                      sx={{ width: '10%' }}
                      onClick={() =>
                        setDevice({
                          ...device,
                          repairServices: device.repairServices.filter((s, index) => index !== i),
                        })
                      }
                    >
                      <Close color="error" />
                    </IconButton>
                  </div>
                ))}
                <Button
                  fullWidth
                  startIcon={<Add />}
                  onClick={() =>
                    setDevice({
                      ...device,
                      repairServices: [...device.repairServices, { name: '', price: 0 }],
                    })
                  }
                >
                  {t('Add Service')}
                </Button>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <Divider />

        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Button
            disabled={device.model === '' || device.brand === '' || device.deviceType === ''}
            variant="contained"
            size="large"
            fullWidth
            onClick={currentAction === 'editing device' ? handleUpdate : handleAdd}
          >
            {currentAction === 'editing device' ? t('Update Device') : t('Add Device')}
          </Button>
        </div>
      </Paper>
    </div>
  );
}

export default DeviceActions;
