import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getDeviceTypes } from '../../../Redux/Actions';

import { DataGrid, deDE } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import '../../../App.css';
import { Icon, IconButton, LinearProgress } from '@mui/material';
import { Devices, ReplayOutlined } from '@mui/icons-material';
import { useState } from 'react';

import FunctionChecks from './FunctionChecks';
import { useTranslation } from 'react-i18next';
import DeviceCard from './DeviceCard';

function CustomToolbar(props, showDelete, setShowDelete) {
  const dispatch = useDispatch();

  return (
    <div className="Table-toolbar">
      <Icon
        fontSize="large"
        className="Table-icon"
        sx={{
          '&.MuiIcon-root': {
            height: '1.5em',
          },
        }}
      >
        <Devices fontSize="large" style={{ color: 'white' }} />
      </Icon>
      <div
        className="Table-icon-bg"
        style={{
          background: process.env.REACT_APP_PRIMARY_CLR_DARK,
        }}
      ></div>

      <Typography
        component="h1"
        variant="h4"
        color="secondary"
        // noWrap
        // sx={{ flexGrow: 1 }}
        // style={{ textTransform: "capitalize" }}
      >
        {/* Repairs */}
      </Typography>
      <div>
        {/* <GridToolbarColumnsButton /> */}

        {/* <GridToolbarFilterButton sx={{ fontSize: "1rem" }} variant="outlined" />
        <Button
          sx={{ fontSize: "1rem", marginLeft: "15px" }}
          startIcon={<Add />}
          variant="contained"
          disabled
          onClick={() => dispatch(setBackdropVisible(true, "device type", "adding"))}
        >
          Device Type
        </Button> */}
        <IconButton
          fontSize={'large'}
          sx={{ marginLeft: '10px' }}
          onClick={() => dispatch(getDeviceTypes())}
        >
          <ReplayOutlined fontSize={'large'} color="primary" />
        </IconButton>
        {/* <IconButton
          fontSize={"large"}
          sx={{ marginLeft: "10px" }}
          onClick={() => setShowDelete(!showDelete)}
        >
          <Delete fontSize={"large"} color="primary" />
        </IconButton> */}
      </div>
    </div>
  );
}

export default function DeviceTypes(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deviceTypes = useSelector((state) => state.job.deviceTypes);
  const loading = useSelector((state) => state.job.deviceTypesLoading);
  const [showDelete, setShowDelete] = useState(false);

  const handleDeleteJob = (e, params) => {
    // e.preventDefault();
    // e.stopPropagation();
    // console.log("row :>> ", params);
    // dispatch(deleteJob(params.id));
  };

  // const columns = [
  //   { field: "id", hide: true },
  //   {
  //     field: "name",
  //     headerName: t("Device Type"),
  //     headerClassName: "table-header",
  //     //   editable: true,
  //     // flex: 1,
  //     width: 300,
  //   },
  //   {
  //     field: "functionChecks",
  //     headerName: t("Function Checklist"),
  //     headerClassName: "table-header",
  //     // flex: 5,
  //     width: 400,
  //     renderCell: (params) => <FunctionChecks params={params} />,
  //   },
  //   // {
  //   //   field: "delete",
  //   //   headerName: t("Delete"),
  //   //   headerClassName: "table-header",
  //   //   flex: 1,
  //   //   hide: !showDelete,
  //   //   renderCell: (params) => (
  //   //     <IconButton onClick={(e) => handleDeleteJob(e, params)}>
  //   //       <Delete color="error" />
  //   //     </IconButton>
  //   //   ),
  //   // },
  // ];

  const rows = deviceTypes.map((o, i) => o);

  return (
    //  height: "88vh"
    <div className="relative" style={{ height: '88vh', background: 'white' }}>
      <div className="bg-transparent absolute top-[-10px] right-0 flex items-center justify-end p-2 ">
        <IconButton
          size="small"
          // fontSize={"large"}
          // sx={{ marginLeft: "10px" }}
          onClick={() => dispatch(getDeviceTypes())}
        >
          <ReplayOutlined fontSize={'large'} color="primary" />
        </IconButton>
      </div>
      <div className="flex items-start gap-2 justify-evenly flex-wrap bg-white p-2">
        {rows?.map((device, index) => {
          return <DeviceCard device={device} />;
        })}
        {/* <DataGrid
          density="comfortable"
          loading={loading}
          rows={rows}
          columns={columns}
          headerHeight={50}
          pageSize={20}
          disableSelectionOnClick
          //   onRowClick={(row) => showJobDetails(row)}
          components={{
            Toolbar: () => CustomToolbar(props, showDelete, setShowDelete),
            LoadingOverlay: LinearProgress,
          }}
          sx={{
            minWidth: "700px",
            minHeight: "88vh",
            padding: "15px",
            fontSize: "1.1rem",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .table-header": {
              marginTop: "10px",
            },
            "& .MuiDataGrid-row:hover": {
              cursor: "pointer",
            },
            "& .MuiDataGrid-row": {
              maxHeight: "200px !important",
            },
            "& .MuiDataGrid-cell": {
              maxHeight: "200px !important",
              padding: "20px",
              fontSize: "1.4rem",
            },
            // "& .MuiDataGrid-columnHeaderTitle": {
            //   fontWeight: "bold",

            // },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          //   rowsPerPageOptions={[5]}
          //   checkboxSelection
        /> */}
      </div>
    </div>
  );
}
