import React from "react";

import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./Invoice.css";

export const DummyInvoice = (props) => {
  const { t } = useTranslation();
  const company = useSelector((state) => state.company.companyDetails);
  const invoice = useSelector((state) => state.company.generalInvoice);

  // console.log('barcode', barcode)
  const dateTimeOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone: "Europe/Berlin",
  };
  return (
    <div className="dummy-invoice-box w-full custom:w-[45%]">
      <div className="" style={{ margin: "auto", width: "90%", fontSize: "11px" }}>
        {/* LOGO  */}
        <div
          style={{
            textAlign: "right",
            height: "70px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {company.logoUrl === "" ? (
            <div className="dummy-logo">LOGO</div>
          ) : (
            <img
              src={company.logoUrl}
              alt="company-logo"
              height={70}
              style={{ maxWidth: "180px" }}
            />
          )}
        </div>

        {/* HEADER  */}
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
          <div>
            <ul style={{ listStyle: "none", textAlign: "left", paddingInlineStart: "unset" }}>
              <li>{t("Client")}</li>
            </ul>
          </div>
          <div>
            <ul style={{ listStyle: "none", textAlign: "right", paddingInlineStart: "unset" }}>
              <li>{company.name}</li>
              <li>
                {company.street}&nbsp;{company.houseNo}
              </li>
              <li>
                {company.postalCode}, {company.city}
              </li>
              <li>{company.phone}</li>
            </ul>
          </div>
        </div>

        {/* BODY  */}
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0px" }}>
          <div style={{ textAlign: "left", width: "60%", paddingTop: "20px", marginRight: "20px" }}>
            <div style={{ display: "flex" }}>
              <div>
                <div>
                  <b>{t("Device Data")}</b>
                </div>
                <br />
              </div>
              <div style={{ padding: "0 10px 0 10px" }}>
                <div></div>
              </div>
            </div>
            <h3 style={{ textDecoration: "underline" }}>{invoice.heading}</h3>
            <p style={{ whiteSpace: "pre-line", fontSize: "10px", lineHeight: 1 }}>
              {invoice.introText}
            </p>
          </div>
          <div>
            <ul
              style={{
                listStyle: "none",
                textAlign: "right",
                paddingInlineStart: "unset",
                filter: "blur(2px)",
              }}
            >
              <li>
                <Barcode value={"155348"} height={15} fontSize={10} width={1.5} margin={5} />
              </li>
              <li>
                <Barcode value={"500"} height={15} fontSize={10} width={1.5} margin={5} />
              </li>

              <li>
                <b>{t("Contractor")}:</b>&nbsp;
                {"John De Silva"}
              </li>

              <li>
                <b>Date:</b> {new Date().toLocaleDateString("de-DE", dateTimeOptions)}
              </li>
              <li>
                <b>Return Date:</b> -
              </li>
            </ul>
          </div>
        </div>

        {/* PRICE TABLE  */}
        <table style={{ width: "100%", marginTop: "0px", filter: "blur(2px)" }}>
          <tbody>
            <tr>
              <th style={{ textAlign: "left", width: "50%", borderBottom: "1px solid black" }}>
                Beschreibung
              </th>
              <th style={{ textAlign: "right", borderBottom: "1px solid black" }}>Menge</th>
              <th style={{ textAlign: "right", borderBottom: "1px solid black" }}>Preis</th>
              {/* <th style={{ textAlign: "right", borderBottom: "1px solid black" }}>MwSt.</th> */}
              <th style={{ textAlign: "right", borderBottom: "1px solid black" }}>Gesamt</th>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td>{t("Services")}:</td>
            </tr>
            <tr>
              <td>&nbsp;</td>
            </tr>
            <tr>
              <td></td>
              <td style={{ textAlign: "left" }}>Zwischensumme</td>
              <td colSpan={4} style={{ textAlign: "right" }}>
                0 EUR
              </td>
            </tr>
            {/* <tr>
              <td></td>
              <td style={{ textAlign: "left" }}>20.00% MwSt.</td>
              <td colSpan={4} style={{ textAlign: "right" }}>
                {(totals.total * 0.2).toFixed(2)} EUR
              </td>
            </tr> */}
            <tr>
              <td></td>
              <td style={{ textAlign: "left" }}>Discount</td>
              <td colSpan={4} style={{ textAlign: "right" }}>
                0 EUR
              </td>
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td></td>
              <td style={{ textAlign: "left" }}>Gesamt</td>
              <td colSpan={4} style={{ textAlign: "right" }}>
                0 EUR
              </td>
            </tr>
          </tbody>
        </table>
        <div className="terms-block">
          <div>
            <p style={{ lineHeight: "8px", whiteSpace: "pre-line", fontSize: "8px" }}>
              {invoice.termsConditions}
            </p>
          </div>
          <div
            style={{
              textAlign: "right",
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div style={{ fontSize: "8px" }}>
              {`Mit meiner Unterschrift bestätige ich den Auftrag und stimme den AGB der Firma
                ${company.name} zu`}
              .&nbsp;
            </div>
            <div>
              <div>__________________</div>
              <div style={{ marginTop: "5px" }}>{t("Date, Signature")}</div>
            </div>
          </div>
        </div>
        <div className="divFooter">
          <table style={{ width: "85%", fontSize: "8px" }}>
            <tbody>
              <tr style={{ lineHeight: "8px" }}>
                <td>
                  {company.name}
                  <br />
                  {company.street}&nbsp;{company.houseNo}
                  <br />
                  {company.postalCode}, {company.city}
                  <br />
                  Tel: {company.phone}
                  <br />
                  Email: {company.email}
                  <br />
                  Webseite: {company.website}
                </td>
                <td style={{ textAlign: "left" }}>
                  UID: {company.uid}
                  <br />
                  Registernummer: {company.regNo}
                  <br />
                  {company.city}
                  <br />
                  {company.ceo}
                  <br />
                </td>
                <td>
                  {company.name}
                  <br />
                  {company.bank}
                  <br />
                  IBAN: {company.iban}
                  <br />
                  BIC: {company.bic}
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
