import React from "react";
import { TextField, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useTranslation } from "react-i18next";

import moment from "moment";
import "moment/locale/de";
import { useSelector, useDispatch } from "react-redux";
import { setCustomerDetails, setReturnDate, setDifferentNameInputs } from "../../../Redux/Actions";
import CustomerNameAutocomplete from "../Utils/CustomerNameAutocomplete";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

moment.locale("de");

function Customer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.job.currentJob.customer);
  const returnDate = useSelector((state) => state.job.currentJob.returnDate);
  const invoiceOf = customer.invoiceOf;
  const max1024 = useMediaQuery("(max-width:1024px)");

  const [oldCustomer, setOldCustomer] = React.useState(false);
  const [dateError, setDateError] = React.useState(true);
  const [dateOpen, setDateOpen] = React.useState(false);
  // console.log('dateError :>> ', dateError);

  // console.log('returnDate :>> ', returnDate._d);

  const handleCustomerTypeChange = (event, newValue) => {
    console.log("newValue", newValue);
    dispatch(setCustomerDetails("type", newValue === 0 ? "private" : "company"));
    // setCustomerType(newValue);
  };

  const handleInputs = (attr, value) => {
    dispatch(setCustomerDetails(attr, value));
  };

  const handleDifferentNameInputs = (attr, value) => {
    dispatch(setDifferentNameInputs(attr, value));
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start" }}>
      <div
        style={{
          margin: customer.differentNameOnInvoice ? "10px 30px 20px 20px" : "auto",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          width: max1024 === false ? "50%" : "100%",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={customer.type === "private" ? 0 : customer.type === "company" ? 1 : null}
            onChange={handleCustomerTypeChange}
            centered
          >
            <Tab label={t("Private")} />
            <Tab label={t("Company")} />
          </Tabs>
        </Box>

        {/* <TextField
          label={customer.type === 'private' ? t('Name') : t('Company Name')}
          value={customer.name}
          required
          onChange={(e) => handleInputs('name', e.target.value)}
          margin="normal"
        /> */}

        <CustomerNameAutocomplete
          label={customer.type === "private" ? t("Name") : t("Company Name")}
          value={customer.name}
          handleInputs={handleInputs}
          oldCustomer={oldCustomer}
          setOldCustomer={setOldCustomer}
        />

        {customer.type === "private" ? null : (
          <TextField
            label={t("Company Representative")}
            value={customer.companyRepresentative}
            required
            sx={{ backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
            onChange={(e) => handleInputs("companyRepresentative", e.target.value)}
            margin="normal"
          />
        )}

        <div style={{ display: "flex", width: "100%" }}>
          <TextField
            label={t("Street")}
            value={customer.street}
            onChange={(e) => handleInputs("street", e.target.value)}
            margin="normal"
            sx={{
              width: "80%",
              marginRight: "15px",
              backgroundColor: oldCustomer ? "#faebd7" : "#fff",
            }}
          />
          <TextField
            label={t("House No.")}
            value={customer.houseNo}
            sx={{ width: "20%", backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
            onChange={(e) => handleInputs("houseNo", e.target.value)}
            margin="normal"
          />
        </div>

        <TextField
          label={t("Postal Code")}
          sx={{ backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
          value={customer.postalCode}
          onChange={(e) => handleInputs("postalCode", e.target.value)}
          margin="normal"
        />
        <TextField
          label={t("City")}
          sx={{ backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
          value={customer.city}
          onChange={(e) => handleInputs("city", e.target.value)}
          margin="normal"
        />
        <TextField
          label={t("Phone")}
          sx={{ backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
          required
          value={customer.phone}
          onChange={(e) => handleInputs("phone", e.target.value)}
          margin="normal"
        />

        <div style={{ display: "flex", flexDirection: "column", marginTop: "20px", width: 300 }}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"de"}>
            <MobileDateTimePicker
              onClick={(e) => console.log("e :>> ", e)}
              label={t("Return Date") + "*"}
              required
              minutesStep={15}
              ampm={false}
              disablePast
              value={
                returnDate
                  ? returnDate instanceof Date
                    ? returnDate
                    : new Date(returnDate.seconds * 1000)
                  : returnDate
              }
              onChange={(datetime) => {
                console.log("datetime :>> ", datetime);
                dispatch(setReturnDate(new Date(datetime)));
              }}
              renderInput={(params) => <TextField {...params} />}
              // onError={(error, value) => {
              //   console.log('error :>> ', error);
              //   console.log('value :>> ', value);
              //   if (error && error !== 'minutesStep') {
              //     setDateError(true);
              //   } else {
              //     setDateError(false);
              //   }
              // }}
            />
          </LocalizationProvider>
          {/* {dateError && (
            <Typography variant="caption" style={{ marginLeft: '10px', color: 'red' }}>
              {t('Please enter or select a date and time. dd.mm.yyyy hh:mm')}
            </Typography>
          )} */}
        </div>

        <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
          <Typography>{t("Different name on invoice ?")}</Typography>
          <Checkbox
            checked={customer.differentNameOnInvoice}
            onChange={(e) =>
              dispatch(setCustomerDetails("differentNameOnInvoice", e.target.checked))
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>
      </div>
      {customer.differentNameOnInvoice ? (
        <div
          style={{
            margin: "26px 20px 20px 20px",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <Typography variant="h6">{t("Name on Invoice")}:</Typography>
          <TextField
            label={t("Name")}
            required
            sx={{ backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
            value={invoiceOf.name}
            onChange={(e) => handleDifferentNameInputs("name", e.target.value)}
            margin="normal"
          />
          <div style={{ display: "flex", width: "100%" }}>
            <TextField
              label={t("Street")}
              value={invoiceOf.street}
              onChange={(e) => handleDifferentNameInputs("street", e.target.value)}
              margin="normal"
              sx={{
                width: "80%",
                marginRight: "15px",
                backgroundColor: oldCustomer ? "#faebd7" : "#fff",
              }}
            />
            <TextField
              label={t("House No.")}
              value={invoiceOf.houseNo}
              sx={{ width: "20%", backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
              onChange={(e) => handleDifferentNameInputs("houseNo", e.target.value)}
              margin="normal"
            />
          </div>
          <TextField
            label={t("Postal Code")}
            sx={{ backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
            value={invoiceOf.postalCode}
            onChange={(e) => handleDifferentNameInputs("postalCode", e.target.value)}
            margin="normal"
          />
          <TextField
            label={t("City")}
            sx={{ backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
            value={invoiceOf.city}
            onChange={(e) => handleDifferentNameInputs("city", e.target.value)}
            margin="normal"
          />
          <TextField
            label={t("Phone")}
            sx={{ backgroundColor: oldCustomer ? "#faebd7" : "#fff" }}
            required
            value={invoiceOf.phone}
            onChange={(e) => handleDifferentNameInputs("phone", e.target.value)}
            margin="normal"
          />
        </div>
      ) : null}
    </div>
  );
}

export default Customer;
