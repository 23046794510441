import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  deleteDevice,
  getDevices,
  setBackdropVisible,
  setDeviceToEdit,
} from '../../../Redux/Actions';

import { DataGrid, deDE, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import '../../../App.css';
import {
  Button,
  Chip,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  useMediaQuery,
} from '@mui/material';
import { Add, Delete, ReplayOutlined, Smartphone } from '@mui/icons-material';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const chipStyle = {
  fontSize: '1.15rem',
  height: '45px',
  paddingLeft: '8px',
  paddingRight: '8px',
  marginBottom: '3px',
  marginRight: '8px',
};

function CustomToolbar(props, showDelete, setShowDelete, setDeviceTypeFilter, deviceTypeFilter) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deviceTypes = useSelector((state) => state.job.deviceTypes);
  const max1024 = useMediaQuery('(max-width:1024px)');
  const max1400 = useMediaQuery('(max-width:1400px)');

  return (
    <div className="Table-toolbar flex justify-between">
      {!max1024 && (
        <>
          <Icon
            fontSize="large"
            className="Table-icon"
            sx={{
              '&.MuiIcon-root': {
                height: '1.5em',
              },
            }}
          >
            <Smartphone fontSize="35px" style={{ color: 'white' }} />
          </Icon>
          <div
            className="Table-icon-bg"
            style={{
              background: process.env.REACT_APP_PRIMARY_CLR_DARK,
            }}
          ></div>
        </>
      )}

      <div
        className={clsx('flex', {
          'flex-col-reverse': max1024 === true,
          'w-full': max1024 === true,
          'gap-3': max1024 === true,
        })}
      >
        {max1024 ? (
          <div>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Filters</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={deviceTypeFilter}
                label="Filters"
                onChange={(event) => {
                  setDeviceTypeFilter(event.target.value);
                }}
              >
                <MenuItem value={'All'}>Alle</MenuItem>
                {deviceTypes.map((d, i) => (
                  <MenuItem value={d} key={i}>
                    {d.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div className="ml-4">
            <Chip
              label={'Alle'}
              key={'x'}
              size="small"
              clickable
              sx={chipStyle}
              onClick={() => setDeviceTypeFilter('All')}
              color={deviceTypeFilter === 'All' ? 'primary' : 'default'}
            />
            {deviceTypes.map((d, i) => (
              <Chip
                label={d.name}
                key={i}
                clickable
                size="small"
                sx={chipStyle}
                onClick={() => setDeviceTypeFilter(d)}
                color={
                  deviceTypeFilter !== 'All'
                    ? deviceTypeFilter.id === d.id
                      ? 'primary'
                      : 'default'
                    : 'default'
                }
              />
            ))}
          </div>
        )}
        <div
          className={clsx('flex items-center gap-4', {
            'flex-col-reverse': max1400 === true,
            'w-full': max1400 === true,
            'gap-3': max1400 === true,
          })}
        >
          {/* <GridToolbarColumnsButton /> */}

          {/* <GridToolbarFilterButton sx={{ fontSize: "1rem" }} variant="outlined" /> */}
          <div
            className={clsx('flex gap-4', {
              'flex-col-reverse': max1400 === true,
              'w-full': max1400 === true,
              'gap-3': max1400 === true,
            })}
          >
            <GridToolbarQuickFilter
              debounceMs={500}
              sx={{
                width: '100%',
              }}
              variant="outlined"
              placeholder={t('Search Device')}
            />
            <div className="flex gap-4">
              <Button
                sx={{ fontSize: '1rem' }}
                startIcon={<Add />}
                fullWidth
                size="large"
                variant="contained"
                onClick={() => dispatch(setBackdropVisible(true, 'add device', 'adding'))}
              >
                {t('Device')}
              </Button>
              <div className="flex justify-end">
                <IconButton
                  fontSize={'large'}
                  sx={{ marginLeft: '10px' }}
                  onClick={() => dispatch(getDevices(true))}
                >
                  <ReplayOutlined fontSize={'large'} color="primary" />
                </IconButton>
                <IconButton
                  fontSize={'large'}
                  sx={{ marginLeft: '10px' }}
                  onClick={() => setShowDelete(!showDelete)}
                >
                  <Delete fontSize={'large'} color="primary" />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function DevicesList(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [deviceTypeFilter, setDeviceTypeFilter] = useState('All');
  const brands = useSelector((state) => state.job.brands);
  const devices = useSelector((state) => state.job.devices);
  const deviceTypes = useSelector((state) => state.job.deviceTypes);
  const devicesLoading = useSelector((state) => state.job.devicesLoading);
  const [showDelete, setShowDelete] = useState(false);

  const [filteredDevices, setFilteredDevices] = useState(devices);

  useEffect(() => {
    if (deviceTypeFilter === 'All') {
      setFilteredDevices(devices);
    } else {
      const filtered = devices.filter((d) => d.deviceTypeId === deviceTypeFilter.id);
      setFilteredDevices(filtered);
    }

    return () => {};
  }, [deviceTypeFilter, devices]);

  const handleDeleteDevice = (e, params) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('row :>> ', params);
    dispatch(deleteDevice(params.id));
  };

  const handleRowClick = (params) => {
    console.log('jobs', params);
    dispatch(setDeviceToEdit(devices.filter((d) => d.id === params.row.id)[0]));
    dispatch(setBackdropVisible(true, 'edit device', 'editing device'));
  };

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'brandId',
      headerName: t('Brand'),
      headerClassName: 'table-header-logo',
      // width: 120,
      renderCell: (params) => {
        const brand = brands.filter((b) => b.id === params.row.brandId)[0];
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {brand.logo === '' ? (
              <span>{brand.name}</span>
            ) : (
              <img src={brand.logo} alt={brand.name} width={40} />
            )}
          </div>
        );
      },
    },
    {
      field: 'model',
      headerName: t('Device Name'),
      headerClassName: 'table-header',
      // width: 250,
      flex: 1,
    },
    {
      field: 'deviceType',
      headerName: t('Device Type'),
      headerClassName: 'table-header',
      cellClassName: 'dtype-cell',
      // width: 200,
      flex: 1,
    },
    {
      field: 'delete',
      headerName: t('Delete'),
      headerClassName: 'table-header',
      width: 100,
      hide: !showDelete,
      renderCell: (params) => (
        <IconButton onClick={(e) => handleDeleteDevice(e, params)}>
          <Delete color="error" />
        </IconButton>
      ),
    },
  ];

  const rows = filteredDevices.map((d, i) => ({
    deviceType: deviceTypes.filter((t) => t.id === d.deviceTypeId)[0].name,
    ...d,
  }));

  // console.log("rows", rows);
  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1, background: 'white' }}>
        <DataGrid
          loading={devicesLoading}
          rows={rows}
          columns={columns}
          onRowClick={(params) => handleRowClick(params)}
          keyboard
          headerHeight={65}
          pageSize={100}
          disableSelectionOnClick
          //   onRowClick={(row) => showJobDetails(row)}
          components={{
            Toolbar: () =>
              CustomToolbar(
                props,
                showDelete,
                setShowDelete,
                setDeviceTypeFilter,
                deviceTypeFilter
              ),
            LoadingOverlay: LinearProgress,
          }}
          sx={{
            minWidth: '800px',
            minHeight: '85vh',
            padding: '15px',
            fontSize: '1.1rem',
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .table-header': {
              marginTop: '10px',
            },
            '& .dtype-cell': {
              color: 'grey',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
            '& .MuiDataGrid-row': {
              maxHeight: '300px !important',
            },
            '& .MuiDataGrid-cell': {
              maxHeight: '300px !important',
              padding: '10px',
              fontSize: '1.4rem',
            },
            '& .MuiDataGrid-editInputCell': {
              fontSize: '1.4rem',
            },
            // "& .MuiDataGrid-columnHeaderTitle": {
            //   fontWeight: "bold",

            // },
          }}
          localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
          //   rowsPerPageOptions={[5]}
          //   checkboxSelection
        />
      </div>
    </div>
  );
}
