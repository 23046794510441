import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBackdropVisible, setCurrentJob } from '../../Redux/Actions';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grow from '@mui/material/Grow';
import { Close } from '@mui/icons-material';
import { DialogTitle, IconButton, useMediaQuery } from '@mui/material';

import './Backdrop.css';
import JobWizard from '../Job/JobWizard';
import JobDetails from '../Job/JobDetails/JobDetails';
import AddBrand from '../../pages/Admin/Brands/AddBrand';
import DeviceActions from '../../pages/Admin/Devices/DeviceActions';
import AddItem from '../../pages/Admin/Items/AddItem';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export default function Backdrop({ backdropVisible }) {
  const backdropComponent = useSelector((state) => state.job.backdropComponent);
  const currentJob = useSelector((state) => state.job.currentJob);
  const currentAction = useSelector((state) => state.job.currentAction);
  const max1024 = useMediaQuery('(max-width:1024px)');

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setBackdropVisible(false, '', ''));
    dispatch(setCurrentJob(null));
    // props.setOpenWizard(false);
  };

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: 'white',
          display: 'grid',
          //   alignContent: "center",
          justifyItems: 'center',
        },
        '& .MuiBackdrop-root': { backgroundColor: 'rgb(0 0 0 / 80%)', backdropFilter: 'blur(4px)' },
      }}
      open={backdropVisible}
      TransitionComponent={Transition}
      fullScreen
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="Close-btn">
        <IconButton color="inherit" onClick={handleClose}>
          <Close fontSize="large" />
        </IconButton>
      </div>
      {currentAction !== 'viewing' ? null : (
        <DialogTitle sx={{ textAlign: 'center' }}>
          {currentJob.device.name} | {currentJob.customer.name}
        </DialogTitle>
      )}
      {/* {backdropComponent === "add brand" ? (
        <DialogTitle sx={{ textAlign: "center" }}>
          ADD NEW BRAND
        </DialogTitle>
      ) : null} */}
      <DialogContent
        sx={{
          textAlign: 'center',
          width:
            backdropComponent === 'job wizard' && max1024 === false
              ? '80%'
              : max1024 === true
              ? '100%'
              : '90%',
          overflowY: 'unset',
        }}
      >
        {backdropComponent === 'job wizard' ? <JobWizard /> : null}
        {backdropComponent === 'job details' ? <JobDetails currentJob={currentJob} /> : null}
        {backdropComponent === 'add brand' ? <AddBrand /> : null}
        {backdropComponent === 'add item' ? <AddItem /> : null}
        {backdropComponent === 'add device' || backdropComponent === 'edit device' ? (
          <DeviceActions from="device table" />
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
